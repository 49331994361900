import { IRaffle } from 'src/services/moderateRafflesService';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface LoginParams {
  email: string;
  password: string;
}

interface RegisterParams {
  email: string;
  password: string;
  type: string;
}

interface IPlayerStore {
  giveaways: IRaffle[];
  raffles: IRaffle[];
  previousRaffles: IRaffle[];
  updateField(field: string, value: any): void;
  findGiveawayById(id: string): IRaffle | undefined;
  findRaffleById(id: string): IRaffle | undefined;
  findPreviousRaffleById(id: string): IRaffle | undefined;
}

const usePlayerStore = create(
  devtools<IPlayerStore>((set, get) => ({
    giveaways: [],
    raffles: [],
    previousRaffles: [],

    findGiveawayById: (id: string) => {
      return get().giveaways.find((giveaway) => giveaway.id === id);
    },

    findPreviousRaffleById: (id: string) => {
      return get().previousRaffles.find((raffle) => raffle.id === id);
    },

    findRaffleById: (id: string) => {
      return get().raffles.find((raffle) => raffle.id === id);
    },

    updateField: (field: string, value: any) => set((state) => ({ ...state, [field]: value })),
  })),
);

export default usePlayerStore;
