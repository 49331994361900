import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import { useEffect, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/leftArrow.svg';
import PageLink from 'src/components/PageLink';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import s from './style.module.scss';
import Button from 'src/ui/Button';
import { Link } from 'react-router-dom';
import { links, routes } from 'src/routes';
import { ReactComponent as ShuffleLogoIcon } from 'src/assets/icons/shuffleLogo.svg';
import { ReactComponent as RaneScapeLogoIcon } from 'src/assets/icons/RuneScapeLogo.svg';
import moderateRafflesService, {
  IRaffle,
  IRafflePlayer,
  RaffleCategory,
  RaffleJoinRequirementsType,
  RaffleStatus,
  RaffleType,
} from 'src/services/moderateRafflesService';
import { useQuery } from '@tanstack/react-query';
import RaffleDetailsCard from 'src/components/raffles/RaffleDetailsCard';
import useModal from 'src/hooks/useModal';
import RaffleModal from 'src/components/Admin/modals/RaffleModal/RaffleModal';
import Modal from 'src/ui/Modal';
import BanModal from 'src/components/Admin/modals/BanModal';
import RaffleParticipantsSection from 'src/components/Admin/RaffleParticipantsSection';
import DeleteRaffleModal from 'src/components/Admin/modals/DeleteRaffleModal';
import GiveawayModal from 'src/components/Admin/modals/GiveawayModal/GiveawayModal';
import { REFETCHING_TIME } from 'src/constants/fetching';
import GiveawayDetailsCard from 'src/components/giveaways/giveaway/GiveawayDetailsCard';

const RaffleAdmin = () => {
  const location = useLocation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const playersPage = Number(searchParams.get('playersPage'));
  const { isOpen, openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const [isDeleteRaffleModal, setDeleteRaffleModal] = useState<IRaffle | null>(null);
  const [banningUser, setBanningUser] = useState<IRafflePlayer | null>(null);
  const [backUrl] = useState(location.state?.from || routes.adminRaffles);

  const itemsPerPage = 12;

  const { data, isFetching } = useQuery({
    queryKey: ['raffle', id],
    queryFn: () => moderateRafflesService.getRaffle({ id: id ? id : '' }),
    enabled: !!id,
    refetchInterval: REFETCHING_TIME,
  });

  const { mutate: selectWinners } = useMutation({
    mutationFn: () => moderateRafflesService.winnersSelection({ id: id ? id : '' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['winners', id] });
      toast.success('Winners have been selected');
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error selecting winners';
      toast.error(errorMessage);
      console.error('Error selecting winners:', error);
    },
  });

  const handleDeleteRaffle = () => {
    navigate(routes.adminRaffles);
  };

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('playersPage', value.toString());
      return prev;
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!searchParams.has('playersPage')) {
      setSearchParams((prev) => {
        prev.set('playersPage', '1');
        return prev;
      });
    }
  }, []);

  if (!data && isFetching) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Not Found</div>;
  }

  return (
    <>
      <div className={s.raffle}>
        <div className={s.raffle__head}>
          <PageLink to={backUrl}>
            <LeftArrowIcon />
            Back to All {data.type === RaffleType.GIVEAWAY ? 'Giveaways' : 'Raffles'}
          </PageLink>

          <li className={s.raffle__actions}>
            <Button borderRadius='6px' onClick={openModal}>
              <EditIcon /> Edit
            </Button>

            {data.status === RaffleStatus.COMPLETED &&
              data.joinRequirementsType !== RaffleJoinRequirementsType.BET && (
                <Button borderRadius='6px' onClick={selectWinners}>
                  Re-elect the winners
                </Button>
              )}
            <Button
              color='#C60000'
              isIcon
              borderRadius='6px'
              onClick={() => setDeleteRaffleModal(data)}>
              <TrashIcon />
            </Button>
          </li>
        </div>

        {data.type === RaffleType.GIVEAWAY && (
          <div className={s.raffle__head}>
            {data.meta.providerType === RaffleCategory.SHUFFLE ? (
              <Link to={links.shuffle}>
                <Button>
                  <ShuffleLogoIcon />
                </Button>
              </Link>
            ) : (
              <Link to={links.runescape}>
                <Button>
                  <RaneScapeLogoIcon />
                </Button>
              </Link>
            )}
          </div>
        )}

        <div className={s.raffle__card}>
          {data.type === RaffleType.GIVEAWAY ? (
            <GiveawayDetailsCard isAdmin item={data} />
          ) : (
            <RaffleDetailsCard isAdmin item={data} />
          )}
        </div>

        {data.type === RaffleType.RAFFLE && (
          <RaffleParticipantsSection
            itemsPerPage={itemsPerPage}
            playersPage={playersPage}
            handleChangePage={handleChangePage}
            onBan={(user) => setBanningUser(user)}
          />
        )}
      </div>

      {data.type === RaffleType.GIVEAWAY ? (
        <GiveawayModal open={isOpen} key={data.id} onClose={closeModal} editingItem={data} />
      ) : (
        <RaffleModal open={isOpen} key={data.id} onClose={closeModal} editingRaffle={data} />
      )}

      <Modal
        maxWidth='sm'
        open={!!banningUser}
        handleClose={() => setBanningUser(null)}
        isCloseIcon>
        <BanModal entityId={data.id} user={banningUser} onClose={() => setBanningUser(null)} />
      </Modal>

      <Modal
        maxWidth='xs'
        open={!!isDeleteRaffleModal}
        handleClose={() => setDeleteRaffleModal(null)}>
        <DeleteRaffleModal
          onDelete={handleDeleteRaffle}
          item={isDeleteRaffleModal}
          onClose={() => setDeleteRaffleModal(null)}
        />
      </Modal>
    </>
  );
};

export default RaffleAdmin;
