import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdminNavigation } from 'src/components/Admin/AdminNavigation';
import { ItemDefinition, PlatformOverview, Player, PlayerDetails } from '../../types';
import { axiosGet } from '../../utils/httpClient';
import { makeStyles } from '@mui/styles';
import 'src/assets/CSS/Admin.scss';
import s from './style.module.scss';
import { routes } from 'src/routes';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  select: {
    '&:before': {
      borderColor: 'white !important',
    },
    '&:after': {
      borderColor: 'white !important',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white !important',
    },
    color: 'white !important',
    background: '#202429 !important',
  },
  icon: {
    fill: 'white !important',
  },
  accordion: {
    background: 'linear-gradient(180deg, #041128, #1e1826) !important',
    borderRadius: '10px',
    color: 'white !important',
  },
  chip: {
    color: 'white !important',
    background: '#121418 !important',
    backgroundColor: '#121418 !important',
    border: '1px solid #2a2e38 !important',
    cursor: 'pointer !important',
  },
  card: {
    minWidth: 100,
    maxHeight: 100,
    height: '100%',
    backgroundColor: 'transparent !important',
    margin: '15px',
    border: 'none',
    boxShadow: 'none',
  },
  chipSelected: {
    backgroundSize: 'cover',
    height: '50px',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
    cursor: 'pointer !important',
    backgroundColor: '#080808',
    border: '3px solid #886CFF !important',
  },
  cardContent: {
    backgroundSize: 'cover',
    backgroundColor: '#080808',
    height: '56px',
    border: '2px solid #2a2e38',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: '#acacac',
      background: '#121418',
    },
  },
};

export const dialogStyles = {
  backgroundColor: '#1a1d23',
  color: 'white',
  padding: '25px',
  boxShadow: 'none',
};

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const getDetails = async (): Promise<PlayerDetails> => axiosGet(`/player/details`);
const platformStatistics = async (): Promise<PlatformOverview> => axiosGet(`/moderate/stats`);
const getPlayers = async (): Promise<Array<Player>> => axiosGet(`/moderate/pending-transactions`);
const getShufflePlayers = async (): Promise<Array<Player>> =>
  axiosGet(`/moderate/pending-shuffle-verification`);
const getItemDefinitions = async (): Promise<Array<ItemDefinition>> =>
  axiosGet(`/general/item-defs`);

export default function Admin() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [details, setDetails] = useState<PlayerDetails | undefined>(undefined);
  const [stats, setStats] = useState<PlatformOverview | undefined>(undefined);
  const [players, setPlayers] = useState<Array<Player>>([]);
  const [playersShuffle, setPlayersShuffle] = useState<Array<Player>>([]);
  const [items, setItems] = useState<Array<ItemDefinition>>([]);

  const model = params.model;

  useEffect(() => {
    if (location.pathname === routes.admin) {
      navigate(routes.adminDashboard);
    }
  }, []);

  useEffect(() => {
    getDetails()
      .then((details) => {
        if (details.authority == 'USER') {
          window.location.href = '/';
        }
        getItemDefinitions().then((response) => {
          setDetails(details);
          console.log('details', details);
          setItems(response);
        });
        getPlayers().then((response: Array<Player>) => {
          setPlayers(response);
        });
        getShufflePlayers().then((response: Array<Player>) => {
          setPlayersShuffle(response);
        });
        platformStatistics().then((response) => {
          setStats(response);
        });
      })
      .catch((e) => (window.location.href = '/'));
  }, []);

  return (
    <div className={s.admin__container}>
      <div className={s.admin__nav}>
        <AdminNavigation authority={details ? details.authority : 'USER'} />
      </div>
      <div className='App-contents'>
        <div className={s.admin__content}>
          <Outlet
            context={{
              itemDefs: items,
              items,
              setItems,
              name: model,
              stats,
              players,
              playersShuffle,
            }}
          />
        </div>
      </div>
    </div>
  );
}
