import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as SortIcon } from 'src/assets/icons/sort.svg';

import s from './style.module.scss';

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    column: 'name',
  },
  {
    id: 1,
    title: 'Start Date',
    column: 'startDate',
  },
  {
    id: 2,
    title: 'Type',
    column: 'category',
  },
  {
    id: 3,
    title: 'Status',
    column: 'status',
  },
  {
    id: 4,
    title: 'Actions',
  },
];

const TableHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSortChange = (column: string) => {
    const currentSortColumn = searchParams.get('sortColumn');
    const currentSortOrder = searchParams.get('sortOrder');

    if (currentSortColumn === column) {
      const newSortOrder =
        currentSortOrder === 'asc' ? 'desc' : currentSortOrder === 'desc' ? '' : 'asc';
      if (newSortOrder === '') {
        searchParams.delete('sortColumn');
        searchParams.delete('sortOrder');
      } else {
        searchParams.set('sortColumn', column);
        searchParams.set('sortOrder', newSortOrder);
      }
    } else {
      searchParams.set('sortColumn', column);
      searchParams.set('sortOrder', 'asc');
    }

    setSearchParams(searchParams);
  };

  return (
    <ul className={s.header}>
      {HEADER_LIST.map((item) => (
        <li
          className={s.header__title}
          key={item.id}
          onClick={() => item.column && handleSortChange(item.column)}>
          <SortIcon /> {item.title}
        </li>
      ))}
    </ul>
  );
};

export default TableHeader;
