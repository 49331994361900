import React, { useState } from 'react';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';

import s from './style.module.scss';
import moderateRafflesService, { IRaffle, RaffleStatus } from 'src/services/moderateRafflesService';
import Button from 'src/ui/Button';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const RaffleItem = ({
  item,
  getTypeOption,
  onEdit,
  onDelete,
}: {
  item: IRaffle;
  getTypeOption?: (item: IRaffle) => string;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const [status, setStatus] = useState<string>(item.status);

  const { mutate } = useMutation({
    mutationKey: ['editRaffleStatus'],
    mutationFn: ({ id, status }: { id: string; status: RaffleStatus }) =>
      moderateRafflesService.editRaffleStatus({ id, status }),
    onSuccess: (data, variables) => {
      setStatus(variables.status);
      toast.success('Raffle status updated successfully');
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error updating status';
      toast.error(errorMessage);
      console.error('Update raffle status error:', error);
    },
  });

  const handleEdit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit();
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete();
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newStatus = e.target.value as RaffleStatus;
    mutate({ id: item.id, status: newStatus });
  };

  return (
    <ul className={s.item}>
      <li className={s.item__name}>{item.name}</li>
      <li>{item.startDate}</li>
      {/* <li>{item.type}</li> */}
      <li>{getTypeOption ? getTypeOption(item) : item.category}</li>
      <li>{item.status}</li>
      {/* <li onClick={(e) => e.preventDefault()}>
        <Select options={RAFFLE_STATUS} value={status} onChange={handleChangeStatus} />
      </li> */}
      <li className={s.item__actions}>
        <Button borderRadius='6px' onClick={handleEdit}>
          <EditIcon /> Edit
        </Button>
        <Button color='#C60000' isIcon borderRadius='6px' onClick={handleDelete}>
          <TrashIcon />
        </Button>
      </li>
    </ul>
  );
};

export default RaffleItem;
