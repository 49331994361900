import React from 'react';
import Button from 'src/ui/Button';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';
import Divider from 'src/ui/Divider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moderateRafflesService, {
  BanUserType,
  IBanUserBody,
  IRafflePlayer,
} from 'src/services/moderateRafflesService';
import { toast } from 'react-toastify';

interface IBanUserProps {
  entityId: string;
  user: IRafflePlayer | null;
  onClose: () => void;
}

const BanModal = ({ entityId, user, onClose }: IBanUserProps) => {
  const queryClient = useQueryClient();

  const isBlocked = user?.banned;

  const { mutate: banMutation } = useMutation({
    mutationFn: (data: { accountId: string; body: IBanUserBody }) =>
      moderateRafflesService.banUser(data),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: ['players', entityId] });
      queryClient.invalidateQueries({ queryKey: ['winners', entityId] });
    },
  });

  const { mutate: unbanMutation } = useMutation({
    mutationFn: (data: { accountId: string; body: IBanUserBody }) =>
      moderateRafflesService.unbanUser(data),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: ['players', entityId] });
      queryClient.invalidateQueries({ queryKey: ['winners', entityId] });
    },
  });

  const handleAccountEntirely = () => {
    if (!user) return;

    if (isBlocked) {
      unbanMutation({
        accountId: user?.id,
        body: { reason: '', banType: BanUserType.ENTIRE_ACCOUNT },
      });
    } else {
      banMutation({
        accountId: user?.id,
        body: { reason: '', banType: BanUserType.ENTIRE_ACCOUNT },
      });
    }
  };

  const handleAccountForThisRaffle = () => {
    if (!user) return;

    if (isBlocked) {
      unbanMutation({
        accountId: user?.id,
        body: { reason: '', banType: BanUserType.GAME_ID, entityId: entityId },
      });
    } else {
      banMutation({
        accountId: user?.id,
        body: { reason: '', banType: BanUserType.GAME_ID, entityId: entityId },
      });
    }
  };

  const handleUnBanAccount = () => {
    if (!user) return;

    unbanMutation({
      accountId: user?.id,
      body: { reason: '', banType: BanUserType.ENTIRE_ACCOUNT },
    });

    unbanMutation({
      accountId: user?.id,
      body: { reason: '', banType: BanUserType.GAME_ID, entityId: entityId },
    });

    toast.success('User has been unbanned');
  };

  if (!user) {
    return null;
  }

  return (
    <div className={s.block}>
      <div className={s.block__logo}>
        <img src={LogoImage} alt='logo' />
      </div>

      <div className={s.block__info}>
        <div>
          <h2 className={s.block__title}>Confirm User {isBlocked ? 'Unblock' : 'Block'}</h2>
          <p className={s.block__desc}>
            Are you sure you want to {isBlocked ? 'unblock' : 'block'} this user? You can choose:
          </p>
          <ul className={s.block__list}>
            <li>{isBlocked ? 'Unblock' : 'Block'} for this giveaway only </li>
            <li>{isBlocked ? 'Unblock' : 'Ban'} account entirely</li>
          </ul>
          <p className={s.block__desc}>
            This action can be undone in the user management settings.
          </p>
        </div>

        <div>
          <Divider />
        </div>

        <div className={s.block__btnContainer}>
          {isBlocked ? (
            <Button onClick={handleUnBanAccount} isOutlinedWhite>
              <div className={s.block__btnContent}>Unblock account entirely</div>
            </Button>
          ) : (
            <>
              <Button onClick={handleAccountEntirely} isOutlinedWhite>
                <div className={s.block__btnContent}>
                  {isBlocked ? 'Unblock' : 'Ban'} account entirely
                </div>
              </Button>
              <Button onClick={handleAccountForThisRaffle}>
                <div className={s.block__btnContent}>
                  {isBlocked ? 'Unblock' : 'Ban'} for this giveaway
                </div>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BanModal;
