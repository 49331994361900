import Button from 'src/ui/Button';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';
import Divider from 'src/ui/Divider';
import { Link } from 'react-router';
import { RaffleType } from 'src/services/moderateRafflesService';

interface ICreatedSuccessModalProps {
  itemId: string;
  type: string;
  onShowItem?: () => void;
}

const CreatedSuccessModal = ({ itemId, type, onShowItem }: ICreatedSuccessModalProps) => {
  return (
    <div className={s.block}>
      <div className={s.block__logo}>
        <img src={LogoImage} alt='logo' />
      </div>

      <div className={s.block__info}>
        <div>
          <h2 className={s.block__title}>Success!</h2>

          <p className={s.block__desc}>
            Your {type === RaffleType.RAFFLE ? 'raffle' : 'giveaway'} has been created successfully.
            <br />
            Participants can now join and enjoy the fun!
          </p>
        </div>

        <div>
          <Divider />
        </div>

        <div className={s.block__btnContainer}>
          <Link to={`/admin/raffles/${itemId}`}>
            <Button onClick={onShowItem}>
              <div className={s.block__btnContent}>
                Show {type === RaffleType.RAFFLE ? 'Raffle' : 'Giveaway'}
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CreatedSuccessModal;
