import React from 'react';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';

import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as Discord } from '../../assets/icons/discord.svg';
import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg';
import { ReactComponent as Twich } from '../../assets/icons/twich.svg';
import { ReactComponent as Kick } from '../../assets/icons/kick.svg';
import Button from 'src/ui/Button';

import s from './style.module.scss';
import { Link } from 'react-router-dom';
import { routes } from 'src/routes';

const NAVIGATION_DATA = [
  {
    title: 'Explore',
    links: [
      { title: 'Leaderboard', route: routes.leaderboard },
      { title: 'Points Shop', route: '/' },
      { title: 'Pachinko', route: '/' },
      { title: 'Raffles', route: routes.raffles },
    ],
  },
  {
    title: 'About',
    links: [
      { title: 'Contact', route: 'mailto:contact@btcs.gg' },
      { title: 'FAQ', route: routes.faq },
      { title: 'Terms and conditions', route: routes.tos },
      { title: 'Privacy Policy', route: routes.privacyPolicy },
    ],
  },
];

const SOCIAL_LINKS = [
  { icon: <Twitter />, route: 'https://x.com/btcstv' },
  { icon: <Discord />, route: 'https://discord.gg/btcs' },
  { icon: <Youtube />, route: 'https://youtube.com/btcs' },
  { icon: <Twich />, route: 'https://twitch.tv/btcs' },
  { icon: <Kick />, route: 'https://kick.com/btcs' },
];

const NavigationItem = ({
  title,
  links,
}: {
  title: string;
  links: { title: string; route: string }[];
}) => {
  return (
    <div className={s.navigationItem}>
      <div className={s.navigationItem__title}>{title}</div>

      <div className={s.navigationItem__links}>
        {links.map((link) => (
          <a href={link.route}>{link.title}</a>
        ))}
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className={s.footer}>
      <div className='container'>
        <div className={s.footer__content}>
          <div className={s.footer__main}>
            <div className={s.footer__logo}>
              <Link to={routes.home}>
                <LogoIcon />
              </Link>
            </div>

            {/* <div className={s.footer__info}>
              Lorem ipsum dolor sit amet consectetur. Imperdiet laoreet sapien sem egestas
              ullamcorper sollicitudin tempor vehicula. Tellus sit faucibus eu in mi aliquam.
            </div> */}

            <div className={s.footer__social}>
              {SOCIAL_LINKS.map((item) => (
                <Link to={item.route}>
                  <Button isIcon>{item.icon}</Button>
                </Link>
              ))}
            </div>
          </div>

          <div className={s.footer__navigation}>
            {NAVIGATION_DATA.map((item) => (
              <NavigationItem key={item.title} title={item.title} links={item.links} />
            ))}
          </div>
        </div>

        <div className={s.footer__rights}>2024. All rights reserved</div>
      </div>
    </div>
  );
};

export default Footer;
