import React, { useEffect, useState } from 'react';
import { axiosGet, axiosPut } from '../utils/httpClient';
import { msToTime } from '../utils/utils';
import { Achievement, ItemDefinition, PlayerDetails } from '../types';
import '../assets/CSS/VIP.scss';
import { Login } from 'src/components/Header';
import SettingsModal from 'src/components/SettingsModal';
import useAuthStore from 'src/store/authStore';
import Button from 'src/ui/Button';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

const getVip = async (): Promise<number> => axiosGet(`/general/vip-reward`);

const getClaims = async (): Promise<Array<Achievement>> => axiosGet(`/player/vip-claims`);
const claimRewards = async (index: number): Promise<boolean> =>
  axiosPut(`/player/claim-vip/${index}`, {});

export const VIP = () => {
  const { data: defs, isLoading: isLoadingDefs } = useQuery({
    queryKey: ['item-defs'],
    queryFn: () => axiosGet('/general/item-defs'),
  });

  const { user } = useAuthStore();
  const [time, setTime] = useState<number>(0);
  const [claims, setClaims] = useState<Array<Achievement>>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const contentRef = React.useRef<any>();

  useEffect(() => {
    getVip().then((r) => {
      setTime(r - new Date().getTime());
    });
    getClaims()
      .then((r) => {
        setClaims(r);
      })
      .catch((e) => console.log(e));
  }, []);

  const eventTime = msToTime(time);

  const tiers = [
    { name: 'Wood', req: 10000, rew: '$20 Reward', ico: '/vip-img/wood.svg', achieved: false },
    { name: 'Bronze', req: 50000, rew: '$100 Reward', ico: '/vip-img/bronze.svg', achieved: false },
    {
      name: 'Silver',
      req: 100000,
      rew: '$2000 Reward',
      ico: '/vip-img/silver.svg',
      achieved: false,
    },
    { name: 'Gold', req: 250000, rew: '$500 Reward', ico: '/vip-img/gold.svg', achieved: false },
    { name: 'Jade', req: 500000, rew: '$1,000 Reward', ico: '/vip-img/jade.svg', achieved: false },
    {
      name: 'Sapphire',
      req: 1000000,
      rew: '$2,000 Reward',
      ico: '/vip-img/sapphire.svg',
      achieved: false,
    },
    {
      name: 'Emerald',
      req: 2500000,
      rew: '$5,000 Reward',
      ico: '/vip-img/emerald.svg',
      achieved: false,
    },
    {
      name: 'Ruby',
      req: 5000000,
      rew: '$10,000 Reward',
      ico: '/vip-img/ruby.svg',
      achieved: false,
    },
    {
      name: 'Obsidian',
      req: 10000000,
      rew: '$20,000 Reward',
      ico: '/vip-img/obsidian.svg',
      achieved: false,
    },
    {
      name: 'Amethyst',
      req: 50000000,
      rew: '$100,000 Reward',
      ico: '/vip-img/Amethyst.svg',
      achieved: false,
    },
  ];

  const getCompletionPerCentage = (wagered: number): number => {
    const required = tiers[getCurrentTierIndex(wagered) + 1].req;
    if (required > 0) {
      return (wagered / required) * 100;
    }
    return 0.0;
  };

  const getCurrentTierIndex = (wagered: number): number => {
    let returnIndex = -1;
    tiers.map((tier, index) => {
      if (tier.req > wagered && returnIndex == -1) {
        returnIndex = index - 1;
      }
    });
    if (returnIndex != -1) {
      return returnIndex;
    }
    return 0;
  };

  if (isLoadingDefs) {
    return <div>Loading...</div>;
  }

  defs
    .filter((d) => d.description == '<vip_rank>')
    .forEach((def, index) => {
      tiers[index].rew = def.name;
      tiers[index].ico = def.image;
      tiers[index].achieved = claims.find((c) => c.metadata == `${index}`) != null;
    });

  const flatList = () => {
    return (
      <div
        className='Vip-Rewards-List-container'
        ref={contentRef}
        style={{ overflow: 'hidden', width: '100%' }}>
        <div className='Vip-Rewards-List'>
          {tiers.map((t, index) => {
            const claimed = t.achieved;
            return (
              <div className='Vip-Reward-Entry'>
                <div className='Icon'>
                  <p>
                    <img src={t.ico} />
                  </p>
                </div>
                <div className='Text'>
                  <div className='wager'>Wager ${t.req.toLocaleString('en-US')}</div>
                  <div className='reward'>{t.rew}</div>
                </div>
                <div className='Action'>
                  <button
                    className={claimed ? 'Alt' : ''}
                    onClick={() => {
                      claimRewards(index)
                        .then((r) => {
                          if (r) {
                            window.location.reload();
                          }
                        })
                        .catch((e) => console.log(e));
                    }}>
                    {claimed ? 'Claimed' : 'Claim'}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  function getCurrentTier(userDetails: PlayerDetails | undefined) {
    if (!userDetails) {
      const { url } = Login();

      return (
        <div className='Current-Tier'>
          <div className='Current-Tier-Info'>
            <div className='Current-Tier-Image'>
              <img src='https://shuffle.com/images/vip/diamond.svg' />
            </div>
            <div className='Current-Tier-Content'>
              <div className='Current-Tier-Title'>
                <h4>Sign In</h4>
              </div>
              <div className='Current-Tier-Description'>
                <p>
                  Please <b>Sign In</b> to get started. To learn more about rewards scroll down.
                </p>
              </div>
            </div>
          </div>
          <div className='Current-Tier-Claim'>
            <Link to={url}>
              <Button borderRadius='6px'>Login</Button>
            </Link>
          </div>
        </div>
      );
    }

    if (userDetails.shuffleWagered == -1) {
      return (
        <div className='Current-Tier'>
          <div className='Current-Tier-Info'>
            <div className='Current-Tier-Image'>
              <img src='https://shuffle.com/images/vip/diamond.svg' />
            </div>
            <div className='Current-Tier-Content'>
              <div className='Current-Tier-Title'>
                <h4>Connect Shuffle</h4>
              </div>
              <div className='Current-Tier-Description'>
                <p>
                  You must <b>verify your Shuffle account</b> to get started. You can configure this
                  in account settings.
                </p>
              </div>
            </div>
          </div>
          <div className='Current-Tier-Claim'>
            <button onClick={() => setOpen(true)}>Verify</button>
          </div>
        </div>
      );
    }

    return (
      <div className='Current-Tier'>
        <div className='Current-Tier-Info'>
          <div className='Current-Tier-Image'>
            <img src={tiers[getCurrentTierIndex(userDetails.shuffleWagered)].ico} />
          </div>
          <div className='Current-Tier-Content'>
            <div className='Current-Tier-Title'>
              <h4>{tiers[getCurrentTierIndex(userDetails.shuffleWagered)].name} Tier</h4>
            </div>
            <div className='Current-Tier-Description'>
              <p>
                Your current tier is{' '}
                <b>{tiers[getCurrentTierIndex(userDetails.shuffleWagered)].name}</b>. This is based
                on your wagers with us. To learn more about your rewards scroll down.
              </p>
            </div>
          </div>
        </div>
        <div className='Current-Tier-Claim'>
          <button
            onClick={() => {
              contentRef.current.scrollIntoView({ behavior: 'smooth' });
            }}>
            Claim Rewards
          </button>
        </div>
      </div>
    );
  }

  function getCurrentTierProgress(userDetails: PlayerDetails | undefined) {
    if (!userDetails) {
      return (
        <div className='Tier-Progress-container'>
          <div className='Tier-Progression'>
            <div className='Progression-Current-Tier'>
              <ul>
                <li>
                  <img src='https://shuffle.com/images/vip/wood.svg' />
                </li>
                <li>
                  <span>Wood tier</span>
                </li>
              </ul>
            </div>
            <div className='Progression-Progress'>
              <div className='Progression-Progress-bar'>
                <progress value='0' max='100' />
              </div>
              <div className='Progression-Progress-value'>
                <span>0%</span>
              </div>
            </div>
            <div className='Progression-Next-Tier'>
              <ul>
                <li>
                  <img src='https://shuffle.com/images/vip/bronze.svg' />
                </li>
                <li>
                  <span>Bronze Tier</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }

    if (userDetails.shuffleWagered <= 0) {
      return (
        <div className='Tier-Progress-container'>
          <div className='Tier-Progression'>
            <div className='Progression-Current-Tier'>
              <ul>
                <li>
                  <img src='https://shuffle.com/images/vip/wood.svg' />
                </li>
                <li>
                  <span>Wood tier</span>
                </li>
              </ul>
            </div>
            <div className='Progression-Progress'>
              <div className='Progression-Progress-bar'>
                <progress value='0' max='100' />
              </div>
              <div className='Progression-Progress-value'>
                <span>0%</span>
              </div>
            </div>
            <div className='Progression-Next-Tier'>
              <ul>
                <li>
                  <img src='https://shuffle.com/images/vip/bronze.svg' />
                </li>
                <li>
                  <span>Bronze Tier</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='Tier-Progress-container'>
        <div className='Tier-Progression'>
          <div className='Progression-Current-Tier'>
            <ul>
              <li>
                <img src={tiers[getCurrentTierIndex(userDetails.shuffleWagered)].ico} />
              </li>
              <li>
                <span>{tiers[getCurrentTierIndex(userDetails.shuffleWagered)].name}</span>
              </li>
            </ul>
          </div>
          <div className='Progression-Progress'>
            <div className='Progression-Progress-bar'>
              <progress
                value={userDetails.shuffleWagered}
                max={tiers[getCurrentTierIndex(userDetails.shuffleWagered) + 1].req}
              />
            </div>
            <div className='Progression-Progress-value'>
              <span>{getCompletionPerCentage(userDetails.shuffleWagered).toFixed(2)}%</span>
            </div>
          </div>
          <div className='Progression-Next-Tier'>
            <ul>
              <li>
                <img src={tiers[getCurrentTierIndex(userDetails.shuffleWagered) + 1].ico} />
              </li>
              <li>
                <span>{tiers[getCurrentTierIndex(userDetails.shuffleWagered) + 1].name}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='App-contents Challenges VIP'>
        <div className='Landing-content'>
          <br />
          <br />
          <div className='Landing-heading'>
            <h2 className='Main-heading Main-heading-larger'>VIP Rewards</h2>
            <div className='Heading-divider' />
            <p className='Main-subheading'>
              Wager to claim <b>November's</b> rewards!
            </p>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />

          <div className='Current-Tier-Container'>{getCurrentTier(user)}</div>

          <br />
          <br />
          <br />
          <br />
          <br />

          {getCurrentTierProgress(user)}

          {flatList()}
        </div>

        {user ? <SettingsModal open={open} onClose={() => setOpen(false)} details={user} /> : <></>}
      </div>
    </>
  );
};
