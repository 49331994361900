import React, { useEffect, useState } from 'react';
import { StaticList } from 'src/types';
import { axiosGet, axiosGetBlob, backend, postFile } from 'src/utils/httpClient';
import Tabs from 'src/components/Tabs/Tabs';
import EditBlacklistModal from 'src/components/Admin/modals/EditBlacklistModal';
import 'src/assets/CSS/Admin.scss';
import Button from 'src/ui/Button';

const LeaderboardAdmin = () => {
  const getBlacklist = async (): Promise<StaticList> => axiosGet(`/admin/blacklist`);
  const getLeaderboardPrizes = async (): Promise<StaticList> =>
    axiosGet(`/admin/leaderboard-prizes`);
  const getSnapshotsList = async (): Promise<Array<string>> =>
    axiosGet(`/moderate/leaderboard-snapshots`);
  const uploadLeaderboardData = async (file: any): Promise<boolean> =>
    postFile(backend, `/moderate/leaderboard-data`, file);
  const downloadSnapshot = async (file: string): Promise<Blob | MediaSource> =>
    axiosGetBlob(`/moderate/download-snapshot/${file}`);
  const downloadLatestSnapshot = async (): Promise<Blob | MediaSource> =>
    axiosGetBlob(`/moderate/download-snapshot/latest`);

  const [blacklist, setBlacklist] = useState<StaticList>({ strings: '' });
  const [prizes, setPrizes] = useState<StaticList>({ strings: '' });
  const [snapshots, setSnapshots] = useState<Array<string>>([]);
  const [selectedSnapshot, setSelectedSnapshot] = useState<string>('-1');
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>(undefined);
  const [openPrizes, setOpenPrizes] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePrizes = () => {
    setOpenPrizes(false);
  };

  useEffect(() => {
    getBlacklist().then((response: StaticList) => {
      setBlacklist(response);
    });
    getLeaderboardPrizes().then((response: StaticList) => {
      setPrizes(response);
    });
    getSnapshotsList().then((response: Array<string>) => {
      setSnapshots(response);
    });
  }, []);
  const names = blacklist.strings.split('\n');
  const prizeNames = prizes.strings.split('\n');

  return (
    <div>
      <div className='Section-heading'>
        <h4>Leaderboard</h4>
      </div>
      <br />
      <br />
      <br />
      <div className='Leaderboard-admin'>
        <div className='Leaderboards-overview'>
          <ul>
            <li>
              <form id='uploadbanner' encType='multipart/form-data'>
                <input
                  id='fileupload'
                  name='leaderboard_data'
                  type='file'
                  onChange={(event: any) => setSelectedFile(event.target.files[0])}
                />
              </form>
            </li>
            <li>
              <Button
                borderRadius='6px'
                customStyle={{ padding: '5px' }}
                onClick={() => {
                  if (!selectedFile) return;
                  console.log(selectedFile);

                  // const uploadFile = (file: any) => {
                  const formData = new FormData();
                  formData.append('file', selectedFile);
                  //     axios.post("https://api.btcs.gg/moderate/leaderboard-data", formData, {
                  //         headers: {
                  //             "Content-Type": "multipart/form-data",
                  //         },
                  //     }).then((response) => {
                  //         // fnSuccess(response);
                  //         console.log("success")
                  //         console.log(response)
                  //     }).catch((error) => {
                  //         // fnFail(error);
                  //         console.log("fail")
                  //         console.log(error)
                  //     });
                  // };
                  // uploadFile(selectedFile);

                  uploadLeaderboardData(formData).then((r) => {
                    console.log(r);
                  });
                }}>
                Upload Leaderboard Data
              </Button>
            </li>
          </ul>
          <ul>
            <li>
              <select
                defaultValue={selectedSnapshot}
                onChange={(e) => setSelectedSnapshot(e.target.value)}>
                <option value='-1'>Select Snapshot</option>
                {snapshots.map((s) => (
                  <option value={s}>{s}</option>
                ))}
              </select>
            </li>
            <li>
              <Button
                className={selectedSnapshot == '-1' ? 'Alt' : ''}
                onClick={() => {
                  if (selectedSnapshot != '-1') {
                    downloadSnapshot(selectedSnapshot).then((blob) => {
                      const href = window.URL.createObjectURL(blob);
                      const link = document.createElement('a');
                      link.href = href;
                      link.setAttribute('download', 'btcs_leaderboard_snapshot.csv'); //or any other extension
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });
                  }
                }}>
                Download Snapshot
              </Button>
            </li>
          </ul>
          <ul>
            <li>
              <Button
                borderRadius='6px'
                onClick={() => {
                  downloadLatestSnapshot().then((blob) => {
                    const href = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'btcs_leaderboard_snapshot.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  });
                }}>
                Download Current Leaderboard Data
              </Button>
            </li>
          </ul>
        </div>
        <div className='Leaderboard-blacklist'>
          {/* @ts-ignore */}
          <Tabs>
            {/* @ts-ignore */}
            <div label='Prizes'>
              <div className='Section-heading'>
                <h4>Prizes</h4>
                <Button
                  onClick={() => setOpenPrizes(true)}
                  customStyle={{
                    borderRadius: '6px',
                    padding: '5px',
                    fontSize: '16px',
                  }}>
                  Edit
                </Button>
              </div>

              <div className='Blacklist'>
                <ul>
                  {prizeNames.map((name) => (
                    <li>{name}</li>
                  ))}
                </ul>
              </div>
            </div>
            {/* @ts-ignore */}
            <div label='Blacklist'>
              <div className='Section-heading'>
                <h4>Blacklist</h4>
                <Button
                  customStyle={{
                    borderRadius: '6px',
                    padding: '5px',
                    fontSize: '16px',
                  }}
                  onClick={() => setOpen(true)}>
                  Edit
                </Button>
              </div>

              <div className='Blacklist'>
                <ul>
                  {names.map((name) => (
                    <li>{name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </Tabs>
        </div>
      </div>

      <EditBlacklistModal
        key={prizes.strings}
        open={openPrizes}
        onClose={handleClosePrizes}
        strings={prizes.strings}
        title={'Edit Leaderboard Prize List'}
        endpoint={`/admin/modify-prizes`}
      />

      <EditBlacklistModal
        key={blacklist.strings}
        open={open}
        onClose={handleClose}
        strings={blacklist.strings}
        title={'Edit Leaderboard Blacklist'}
        endpoint={`/admin/modify-blacklist`}
      />
    </div>
  );
};

export default LeaderboardAdmin;
