import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import {
  CreateItemDefinitionRequest,
  EditItemDefinitionRequest,
  ItemDefinition,
  StoreItem,
} from 'src/types';
import { axiosPost, axiosPut } from 'src/utils/httpClient';

export type ItemModalProps = {
  items: Array<ItemDefinition>;
  editing?: ItemDefinition;
  setItems: any;
} & ModalProps;

function AddItemModal(props: ItemModalProps) {
  const { onClose, open, setItems } = props;

  const [name, setName] = useState<string | undefined>(undefined);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const createItem = async (request: CreateItemDefinitionRequest): Promise<Array<StoreItem>> =>
    axiosPost(`/admin/create-item`, request);
  const editItem = async (request: EditItemDefinitionRequest): Promise<Array<StoreItem>> =>
    axiosPut(`/admin/edit-item`, request);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    console.log(props.editing);
    if (props.editing) {
      setName(props.editing.name);
      setImage(props.editing.image);
      setDescription(props.editing.description);
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>{props.editing ? 'Edit' : 'Add'} Item</h2>
      <div className='Btcs-form'>
        <div className='Form-input-field'>
          <h6>Item Name</h6>
          <div className='Form-input'>
            <input
              type='text'
              placeholder='Item Name...'
              defaultValue={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='Form-input-field'>
          <h6>Image URL</h6>
          <div className='Form-input'>
            <input
              type='text'
              placeholder='Item Image...'
              defaultValue={image}
              onChange={(e) => {
                setImage(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='Form-input-field'>
          <h6>Description</h6>
          <div className='Form-input'>
            <input
              type='text'
              placeholder='Item Description...'
              defaultValue={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='Form-submission'>
          <button
            className='Alt'
            onClick={() => {
              if (submitting) {
                return;
              }
              if (!name || !image || !description) {
                return;
              }
              setSubmitting(true);
              if (props.editing) {
                editItem({
                  id: props.editing.id,
                  name: name,
                  image: image,
                  description: description,
                }).then((response) => {
                  setSubmitting(false);
                  setName(undefined);
                  setImage(undefined);
                  setDescription(undefined);
                  onClose();
                  setItems(response);
                });
              } else {
                createItem({
                  name: name,
                  image: image,
                  description: description,
                }).then((response) => {
                  setSubmitting(false);
                  setName(undefined);
                  setImage(undefined);
                  setDescription(undefined);
                  onClose();
                  setItems(response);
                });
              }
            }}>
            {submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Create') + ' Item'}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddItemModal;
