import React, { forwardRef } from 'react';
import { ActionMeta, default as ReactSelect, SingleValue } from 'react-select';

import styles from './style.module.scss';

interface ISelectProps {
  label: string;
  name: string;
  value: string | number | null;
  onChange: (
    newValue: SingleValue<string | number>,
    actionMeta: ActionMeta<string | number>,
  ) => void;
  options: { label: number | string; value: number | string }[];
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  getOptionalLabel?: (option: any) => string;
  isLoading?: boolean;
  className?: string;
}

const Select = forwardRef(
  (
    {
      options,
      isLoading,
      getOptionalLabel,
      value,
      onChange,
      placeholder,
      label,
      error,
    }: ISelectProps,
    ref: any,
  ) => {
    const isError = !!error;

    return (
      <div className={styles.container}>
        {label && <label className={styles.label}>{label}</label>}

        <ReactSelect
          // @ts-ignore
          options={options}
          isLoading={isLoading}
          getOptionLabel={getOptionalLabel}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          ref={ref}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'white',
              primary: 'white',
            },
          })}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: 'transparent',
              borderColor: isError ? 'red' : baseStyles.borderColor,
              padding: '8px',
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: 'white',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: '#2c2635',
            }),
            option: (baseStyles, { isFocused, isSelected }) => ({
              ...baseStyles,
              color: 'white',
              ':active': {},
              backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
        />

        {isError && <p className={styles.error}>{error}</p>}
      </div>
    );
  },
);

export default Select;
