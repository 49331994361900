import React, { useEffect, useState } from 'react';
import {
  Giveaway,
  Item,
  ItemDefinition,
  ObtainedItem,
  Player,
  PlayerAddItemRequest,
  PlayerMoveItemRequest,
  WatchTime,
} from 'src/types';
import {
  axiosPost,
  axiosPut,
} from 'src/utils/httpClient';
import moment from 'moment';
import Tabs from 'src/components/Tabs/Tabs';
import { useStyles } from 'src/layouts/AdminLayout';
import { formatDateYearTime } from '../../components/Crates';
import { Card, CardContent, Grid } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import 'src/assets/CSS/Admin.scss';
import { useOutletContext } from 'react-router-dom';

const Items = (
  classes: any,
  defs: Array<ItemDefinition>,
  inventory?: Array<ObtainedItem>,
  selected?: Array<ObtainedItem>,
  setSelectedItem?: any,
) => {
  const map = new Map<number, ItemDefinition>();
  defs.map((def) => map.set(def.id, def));
  return (
    <Grid className={classes.gridList} spacing={0} container>
      {inventory!.map((tile) => {
        const currentlySelected = selected && selected.find((i2) => i2.id == tile.id);
        return (
          <Card
            sx={{ minWidth: 100, maxWidth: 100, minHeight: 100, maxHeight: 100 }}
            onClick={() => {
              if (setSelectedItem) {
                setSelectedItem(tile);
              }
            }}
            className={classes.card}>
            <CardContent
              className={currentlySelected ? classes.chipSelected : classes.cardContent}
              style={{ backgroundImage: `url(${defs.find((d) => d.id == tile.item)?.image})` }}>
              <span>{tile.quantity}x</span>
            </CardContent>
          </Card>
        );
      })}
    </Grid>
  );
};

const PlayerViewAdmin = () => {
  const { items, name } = useOutletContext<any>();

  console.log('name', name);
  console.log('items', items);

  const classes = useStyles();
  const getPlayer = async (id: string): Promise<Player> => axiosPost(`/admin/player`, { uuid: id });
  const modifyPoints = async (id: string, increase: boolean, points: number): Promise<Player> =>
    axiosPost(`/admin/modify-points`, { uuid: id, increase: increase, points: points });
  const modifyPrizeUsd = async (id: string, increase: boolean, points: number): Promise<Player> =>
    axiosPost(`/admin/modify-prize`, { uuid: id, increase: increase, points: points });
  const verifyShuffle = async (id: string): Promise<Array<Giveaway>> =>
    axiosPut(`/moderate/verify-shuffle`, { uuid: id });
  const resetShuffle = async (id: string): Promise<Array<Giveaway>> =>
    axiosPut(`/moderate/reset-shuffle`, { uuid: id });

  const addItem = async (request: PlayerAddItemRequest): Promise<Player> =>
    axiosPost(`/admin/add-item`, request);
  const moveItem = async (request: PlayerMoveItemRequest): Promise<Player> =>
    axiosPost(`/admin/move-item`, request);

  const [adding, setAdding] = useState<number>(0);
  const [addingPrize, setAddingPrize] = useState<number>(0);
  const [addingItem, setAddingItem] = useState<boolean>(false);
  const [movingItem, setMovingItem] = useState<boolean>(false);
  const [addingInventory, setAddingInventory] = useState<Item | undefined>(undefined);
  const [points, setPoints] = useState<number>(0);
  const [prize, setPrize] = useState<number>(0);
  const [player, setPlayer] = useState<Player | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState<Array<ObtainedItem>>([]);

  const setSelectedItem = (item: ObtainedItem) => {
    const exists = selectedItems.find((i2) => i2.id == item.id);
    const newItems: Array<ObtainedItem> = [];
    selectedItems.map((i2) => {
      if (exists) {
        if (item.id != i2.id) {
          newItems.push(i2);
        }
      } else {
        newItems.push(i2);
      }
    });
    if (!exists) {
      newItems.push(item);
    }
    setSelectedItems(newItems);
  };

  useEffect(() => {
    getPlayer(name).then((response) => {
      setPlayer(response);
    });
  }, []);

  const getBreakdown = (times: Array<WatchTime>) => {
    return (
      <div className='Breakdowns'>
        {times.map((t) => {
          return (
            <div className='Breakdown'>
              <div className='Breakdown-title'>{t.channelName}</div>
              <div className='Viewership'>
                <ul>
                  <li className='Platform'>
                    <div className='Platform'>Kick</div>
                    <div className='Time'>{moment.utc(t.kickTime).format('HH:mm:ss')}</div>
                  </li>
                  <li className='Platform'>
                    <div className='Platform'>Twitch</div>
                    <div className='Time'>{moment.utc(t.twitchTime).format('HH:mm:ss')}</div>
                  </li>
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  if (player) {
    return (
      <div>
        <div
          className='Inspect-return'
          onClick={() => {
            window.location.href = 'https://btcs.gg/admin/users';
          }}>
          <ArrowBack />
          <span>Back</span>
        </div>
        <div className='Section-heading'>
          <h4>{player.username}</h4>
        </div>
        <br />
        <br />
        <br />
        <div className='Viewing-player'>
          <div className='Player-overview'>
            <ul>
              <li>Username</li>
              <li>{player.username}</li>
            </ul>
            <ul>
              <li>Rank</li>
              <li>{player.authority}</li>
            </ul>
            <ul>
              <li>Balance</li>
              <li>
                <div className='Balance'>
                  <img src='/currency.svg' />
                  <div className='Points-value'>{player.points.toLocaleString('en-US')}</div>
                </div>
              </li>
            </ul>
            <ul>
              <li>Leaderboard Winnings</li>
              <li>${player.leaderboardPrizes.toLocaleString('en-US')}</li>
            </ul>
            <ul>
              <li>Joined</li>
              <li>{formatDateYearTime(player.joined)}</li>
            </ul>
            <ul>
              <li>Last Online</li>
              <li>{formatDateYearTime(player.lastOnline)}</li>
            </ul>
            <ul>
              <li>BTC Wallet</li>
              <li>{player.btcWallet}</li>
            </ul>
            <ul>
              <li>ETH Wallet</li>
              <li>{player.ethWallet}</li>
            </ul>
            <ul>
              <li>Discord</li>
              <li>{player.discordName}</li>
            </ul>
            <ul>
              <li>Kick Username</li>
              <li>{player.kickName}</li>
            </ul>
            <ul>
              <li>Shuffle Username</li>
              <li>
                {player.shuffleName}{' '}
                {player.shuffleNameApproved ? (
                  <button
                    onClick={() => {
                      resetShuffle(player.id).then((r) => {
                        window.location.reload();
                      });
                    }}>
                    Reset
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        verifyShuffle(player.id).then((r) => {
                          window.location.reload();
                        });
                      }}>
                      Approve
                    </button>
                  </>
                )}{' '}
              </li>
            </ul>
            <ul>
              <li>Watch Time</li>
              <li>{moment.utc(player.watchTime.overall).format('HH:mm:ss')}</li>
            </ul>
            <ul>
              {player.watchTime ? (
                <>
                  <li>Breakdown</li>
                  <li>{getBreakdown(player.watchTime.times)}</li>
                </>
              ) : (
                <></>
              )}
            </ul>
            <br />
            <br />
            <br />
            <br />
            {adding !== 0 ? (
              <div>
                <span>How many points would you like to {adding == -1 ? 'remove' : 'add'}?</span>
                <div className='Btcs-form'>
                  <div className='Form-input-field'>
                    <h6>Points</h6>
                    <div className='Form-input'>
                      <div className='Form-icon'>
                        <img src='/currency.svg' />
                      </div>
                      <input
                        type='number'
                        placeholder='Points'
                        defaultValue={points}
                        onChange={(e) => {
                          setPoints(Number(e.target.value));
                        }}
                      />
                      <h5
                        onClick={() => {
                          if (points > 0) {
                            const increase = adding === 1;
                            setAdding(3);
                            modifyPoints(player.id, increase, points).then((r) => {
                              setPlayer(r);
                              setAdding(0);
                            });
                          }
                        }}>
                        Update
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <ul>
                <li>
                  <button onClick={() => setAdding(1)}>Add Points</button>
                </li>
                <li>
                  <button onClick={() => setAdding(-1)}>Remove Points</button>
                </li>
                <li></li>
              </ul>
            )}
            {addingPrize !== 0 ? (
              <div>
                <span>
                  How many Leaderboard Prize Dollars would you like to{' '}
                  {addingPrize == -1 ? 'remove' : 'add'}?
                </span>
                <div className='Btcs-form'>
                  <div className='Form-input-field'>
                    <h6>Prize ($)</h6>
                    <div className='Form-input'>
                      <div className='Form-icon'>
                        <img src='/currency.svg' />
                      </div>
                      <input
                        type='number'
                        placeholder='Prize ($)'
                        defaultValue={prize}
                        onChange={(e) => {
                          setPrize(Number(e.target.value));
                        }}
                      />
                      <h5
                        onClick={() => {
                          if (prize > 0) {
                            const increase = addingPrize === 1;
                            setAddingPrize(3);
                            modifyPrizeUsd(player.id, increase, prize).then((r) => {
                              setPlayer(r);
                              setAddingPrize(0);
                            });
                          }
                        }}>
                        Update
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <ul>
                <li>
                  <button onClick={() => setAddingPrize(1)}>Add Prize</button>
                </li>
                <li>
                  <button onClick={() => setAddingPrize(-1)}>Remove Prize</button>
                </li>
                <li></li>
              </ul>
            )}
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className='Player-logs'>
            {/* @ts-ignore */}
            <Tabs>
              {/* @ts-ignore */}
              <div label='Pending Items'>
                <div className='Inventory-actions'>
                  {Items(
                    classes,
                    items,
                    player.items.filter((i) => i.status == 'PENDING'),
                    selectedItems,
                    setSelectedItem,
                  )}
                  {selectedItems.length > 0 ? (
                    <ul>
                      <li>
                        <span>With Selected:</span>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            if (!movingItem && selectedItems.length > 0) {
                              setMovingItem(true);
                              selectedItems.map((selected) => {
                                moveItem({
                                  uuid: player.id,
                                  id: selected.item,
                                  quantity: selected.quantity,
                                  cancel: false,
                                }).then((r) => {
                                  setAddingInventory(undefined);
                                  setPlayer(r);
                                });
                              });
                              setMovingItem(false);
                            }
                          }}>
                          {movingItem ? 'Marking...' : 'Mark As Received'}
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            if (!movingItem && selectedItems.length > 0) {
                              setMovingItem(true);
                              selectedItems.map((selected) => {
                                moveItem({
                                  uuid: player.id,
                                  id: selected.item,
                                  quantity: selected.quantity,
                                  cancel: true,
                                }).then((r) => {
                                  setAddingInventory(undefined);
                                  setPlayer(r);
                                });
                              });
                              setMovingItem(false);
                            }
                          }}>
                          {movingItem ? 'Marking...' : 'Mark As Cancelled'}
                        </button>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {addingInventory ? (
                    <ul>
                      <li>
                        <select
                          defaultValue={addingInventory.id}
                          onChange={(e) =>
                            setAddingInventory({
                              id: Number(e.target.value),
                              quantity: addingInventory.quantity,
                            })
                          }>
                          <option value={-1}>Select Item</option>
                          {items.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </li>
                      <li>
                        <input type='number' defaultValue={addingInventory.quantity} />
                      </li>
                      <li>
                        <button
                          className={
                            addingInventory.id > 0 && addingInventory.quantity > 0 ? '' : 'Alt'
                          }
                          onClick={() => {
                            if (!addingItem && addingInventory.id > 0 && addingInventory.quantity) {
                              setAddingItem(true);
                              addItem({
                                uuid: player.id,
                                id: addingInventory.id,
                                quantity: addingInventory.quantity,
                              }).then((r) => {
                                setAddingItem(false);
                                setAddingInventory(undefined);
                                setPlayer(r);
                              });
                            }
                          }}>
                          {addingItem ? 'Confirming...' : 'Confirm Item'}
                        </button>
                      </li>
                      <li>
                        <button onClick={() => setAddingInventory(undefined)}>Cancel Item</button>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        <button
                          onClick={() =>
                            setAddingInventory({
                              id: -1,
                              quantity: 1,
                            })
                          }>
                          Add Item
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              {/* @ts-ignore */}
              <div label='All Items'>{Items(classes, defs, player.items)}</div>
              {/* @ts-ignore */}
              <div label='Logs'>{PlayerLogs(player.logs)}</div>
              {/* @ts-ignore */}
              <div label='Alt Accounts'></div>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className='Section-heading'>
        <h4>Loading...</h4>
      </div>
    </div>
  );
};

export default PlayerViewAdmin;
