import React from 'react';
import s from './style.module.scss';
import { ReactComponent as GiftIcon } from 'src/assets/icons/gift.svg';

import AvatarImage from 'src/assets/images/avatar.png';
import Button from 'src/ui/Button';
import { IRafflePlayer } from 'src/services/moderateRafflesService';

interface IEntryItemProps {
  item: IRafflePlayer;
  isWinner?: boolean;
  isBanned?: boolean;
  isRewarded?: boolean;
  onReward?: ((id: string) => void) | null;
  onBan: () => void;
}

const EntryItem = ({ item, isWinner, isBanned, isRewarded, onReward, onBan }: IEntryItemProps) => {
  return (
    <div className={s.entry}>
      <div className={s.entry__info}>
        <img src={AvatarImage} alt='avatar' />

        <div className={s.entry__name}>
          {item.accountName} {isWinner ? (isRewarded ? '- rewarded' : '- winner') : ''}
        </div>
      </div>

      <div className={s.entry__row}>
        {isWinner && !isRewarded && onReward && (
          <Button className='iconBtn' borderRadius='6px' onClick={() => onReward(item.id)}>
            <GiftIcon /> Reward
          </Button>
        )}

        <Button borderRadius='6px' isOutlinedWhite onClick={onBan}>
          {item.banned ? 'Unban' : 'Ban'}
        </Button>
      </div>
    </div>
  );
};

export default EntryItem;
