import React, { useState } from 'react';
import { ItemDefinition } from 'src/types';
import { axiosPut } from 'src/utils/httpClient';
import AddItemModal from '../../components/Admin/modals/AddItemModal';
import 'src/assets/CSS/Admin.scss';
import { useOutletContext } from 'react-router-dom';
import Button from 'src/ui/Button';

const ItemsAdmin = () => {
  const { items, setItems } = useOutletContext<any>();

  const deleteItem = async (id: number): Promise<Array<ItemDefinition>> =>
    axiosPut(`/admin/delete-item?id=${id}`, {});
  const [editing, setEditing] = useState<ItemDefinition | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setEditing(undefined);
  };

  return (
    <div>
      <div className='Section-heading'>
        <h4>Item Definitions</h4>
        <Button
          customStyle={{
            padding: '5px',
            borderRadius: '6px',
            fontSize: '16px',
          }}
          onClick={() => setOpen(true)}>
          Add Item Defs
        </Button>
      </div>

      <div className='Btcs-table'>
        <ul className='Header'>
          <li>Name</li>
          <li>Action</li>
        </ul>
        {items.map((item) => {
          return (
            <ul className='Entry'>
              <li>{item.name}</li>
              <li className='Actions'>
                <button
                  onClick={() => {
                    setEditing(item);
                    setOpen(true);
                  }}>
                  Edit
                </button>

                <button
                  onClick={() => {
                    deleteItem(item.id).then((r) => {
                      setItems(r);
                    });
                  }}>
                  Delete
                </button>
              </li>
            </ul>
          );
        })}
      </div>

      <AddItemModal
        open={open}
        key={editing ? editing.id : 'no-id-item'}
        onClose={handleClose}
        editing={editing}
        items={items}
        setItems={setItems}
      />
    </div>
  );
};

export default ItemsAdmin;
