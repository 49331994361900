import { useEffect, useState } from 'react';
import { ModalProps } from 'src/layouts/AdminLayout';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { convertDateToIso, convertToDatetimeLocalFormat, convertUtcToEst } from 'src/utils/date';
import moderateRafflesService, {
  IRaffle,
  IRaffleImage,
  RaffleType,
} from 'src/services/moderateRafflesService';
import Datetime from 'react-datetime';

import { toast } from 'react-toastify';
import Modal from 'src/ui/Modal';
import Input from 'src/ui/Input';
import FileUploader from 'src/ui/FileUploader';
import s from './style.module.scss';
import Button from 'src/ui/Button';
import LogoImage from 'src/assets/images/btcs-logo.png';
import Select from 'src/ui/Select';
import { createImageUrl } from 'src/utils/url';
import DynamicList from '../../DynamicList';

export type GiveawayModalProps = {
  editingRaffle: IRaffle | null;
  onSuccessCreated?: (raffle: IRaffle) => void;
} & ModalProps;

const CATEGORIES = [
  {
    id: 0,
    value: 'NONE',
  },
  {
    id: 1,
    value: 'SHUFFLE',
  },
  {
    id: 2,
    value: 'RUNESCAPE',
  },
];

const CONDITION_TYPES = [
  {
    label: 'Watching points',
    value: 'watchingPoints',
    id: 'BET',
  },
  {
    label: 'Subscription',
    value: 'subscription',
    id: 'SUBSCRIPTION',
  },
];

interface IFormState {
  name: string;
  type: RaffleType;
  category: (typeof CATEGORIES)[number];
  description: string;
  startDate: string;
  image: IRaffleImage | null;
  subscriptionLinks: string[];
  betAmount: number;
  endDate: string;
  conditionDescription: string;
  activationDate: string;
  joinRequirementsType: any;
  maxPlayers: number;
  maxWinners: number;
  rewardAmount: number;
  status: string;
  version: number;
}

const clearFormState = {
  name: '',
  type: RaffleType.RAFFLE,
  category: CATEGORIES[0],
  description: '',
  startDate: '',
  image: null,
  conditionDescription: '',
  joinRequirementsType: CONDITION_TYPES[0],
  subscriptionLinks: [''],
  betAmount: 0,
  endDate: '',
  activationDate: '',
  maxPlayers: 0,
  maxWinners: 0,
  rewardAmount: 0,
  status: 'PENDING',
  version: 0,
};

function RaffleModal({ editingRaffle, onSuccessCreated, onClose, open }: GiveawayModalProps) {
  const queryClient = useQueryClient();
  const isEdit = !!editingRaffle;
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<any>('');

  const [formState, setFormState] = useState<IFormState>(clearFormState);

  // Create raffle
  const { mutate: create, isPending: isLoadingCreate } = useMutation({
    mutationFn: (body: any) => moderateRafflesService.createRaffle(body),
    onSuccess: (data) => {
      if (onSuccessCreated) onSuccessCreated(data);
      queryClient.invalidateQueries({ queryKey: ['raffles-admin'] });
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error creating raffle';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Create raffle error:', error);
    },
  });

  // Edit raffle
  const { mutate: edit, isPending: isLoadingEdit } = useMutation({
    mutationFn: (body: any) =>
      moderateRafflesService.editRaffle({ id: editingRaffle?.id || '', body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['raffle', editingRaffle?.id] });
      toast.success('Raffle edited successfully');
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error editing raffle';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Edit raffle error:', error);
    },
  });

  // Create image
  const { mutate: createImage, isPending: isLoadingImage } = useMutation({
    mutationFn: (file: FormData) => moderateRafflesService.createImage(file),
    onSuccess: (response) => {
      handleChange({ target: { name: 'image', value: response } });
      setImage(response);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error upload image';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Error upload image:', error);
    },
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const handleChangeImage = async (file: File | null) => {
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file);
    console.log('file', file);

    for (const pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
    await createImage(formData);
  };

  const formBody = () => {
    const {
      name,
      category,
      description,
      startDate,
      endDate,
      activationDate,
      maxPlayers,
      image,
      maxWinners,
      subscriptionLinks,
      conditionDescription,
      joinRequirementsType,
      betAmount,
      status,
      rewardAmount,
      version,
    } = formState;

    const body = {
      name: name,
      type: RaffleType.RAFFLE,
      category: category.value,
      description: description,
      startDate: convertDateToIso(startDate),
      endDate: convertDateToIso(endDate),
      activationDate: convertDateToIso(activationDate),
      maxPlayers: Number(maxPlayers),
      maxWinners: Number(maxWinners),
      joinRequirementsType: joinRequirementsType.id,
      meta: {
        betAmount: Number(betAmount),
        requiredSubscriptions: subscriptionLinks,
        description: conditionDescription,
        providerType: 'SHUFFLE',
        requiredDuration: [
          {
            platform: 'string',
            duration: 0,
          },
        ],
      },
      rewardAmount: Number(rewardAmount),
      imageId: image?.id,
      status: status,
      version: version,
      players: [],
    };

    return body;
  };

  const onCreate = () => {
    const body = formBody();

    create(body);
  };

  const onEdit = () => {
    const body = formBody();

    edit(body);
  };

  const onCancel = () => {
    onClose();
    setFormState(clearFormState);
  };

  const onSubmit = () => {
    if (isLoadingCreate || isLoadingEdit) {
      return;
    }

    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
  };

  const handleAddLink = () => {
    handleChange({
      target: { name: 'subscriptionLinks', value: [...formState.subscriptionLinks, ''] },
    });
  };

  const handleDeleteLink = (index: number) => {
    const newSubscriptionLinks = formState.subscriptionLinks.filter((_, i) => i !== index);

    handleChange({
      target: {
        name: 'subscriptionLinks',
        value: newSubscriptionLinks,
      },
    });
  };

  const handleChangeLink = (index: number, value: string) => {
    const newSubscriptionLinks = [...formState.subscriptionLinks];

    newSubscriptionLinks[index] = value;

    handleChange({
      target: {
        name: 'subscriptionLinks',
        value: newSubscriptionLinks,
      },
    });
  };

  const handleDeleteImage = () => {
    setImage('');
    handleChange({ target: { name: 'image', value: null } });
  };

  useEffect(() => {
    if (editingRaffle) {
      const findCategory = CATEGORIES.find((category) => category.value === editingRaffle.category);
      const findJoinRequirementsType = CONDITION_TYPES.find(
        (type) => type.id === editingRaffle.joinRequirementsType,
      );

      setFormState({
        name: editingRaffle.name || '',
        type: RaffleType.RAFFLE,
        category: findCategory || CATEGORIES[0],
        description: editingRaffle.description || '',
        joinRequirementsType: findJoinRequirementsType || CONDITION_TYPES[0],
        status: editingRaffle.status || 'PENDING',
        subscriptionLinks: editingRaffle.meta.requiredSubscriptions || [''],
        betAmount: editingRaffle.meta.betAmount || 0,
        conditionDescription: editingRaffle.meta.description || '',
        image: editingRaffle.image || null,
        startDate: convertUtcToEst(editingRaffle.startDate) || '',
        endDate: convertUtcToEst(editingRaffle.endDate) || '',
        activationDate: convertUtcToEst(editingRaffle.activationDate) || '',
        maxPlayers: editingRaffle.maxPlayers || 0,
        maxWinners: editingRaffle.maxWinners || 0,
        rewardAmount: editingRaffle.rewardAmount || 0,
        version: editingRaffle.version || 0,
      });
    }
  }, [editingRaffle]);

  return (
    <Modal open={open} handleClose={handleClose} isCloseIcon>
      <div className={s.modal__header}>
        <img src={LogoImage} alt='logo' />

        <h2>
          {isEdit ? `Edit ` : `Add `}
          Raffle
        </h2>
      </div>

      <div className={s.modal__form}>
        <Input
          label='Name'
          name='name'
          placeholder='Type here'
          value={formState.name}
          onChange={handleChange}
        />

        <Input
          label='Description'
          name='description'
          placeholder='Type here'
          value={formState.description}
          onChange={handleChange}
        />

        {isLoadingImage ? (
          <div>Loading image...</div>
        ) : (
          <FileUploader
            value={image}
            url={createImageUrl(image.url)}
            setValue={handleChangeImage}
            onDelete={handleDeleteImage}
            label='Icon upload'
            title="Attach raffle's logo here"
            name='image'
            acceptedFileTypes={['image/jpeg', 'image/png']}
          />
        )}

        <div className={s.modal__row}>
          <Input
            label='Limit of participants'
            type='number'
            name='maxPlayers'
            placeholder='Type here'
            onChange={handleChange}
            value={formState.maxPlayers}
            min={0}
          />

          <Input
            label='Number of winners'
            type='number'
            name='maxWinners'
            placeholder='Type here'
            onChange={handleChange}
            value={formState.maxWinners}
            min={0}
          />
        </div>

        <div className={s.modal__row}>
          {/* <Datetime
            inputProps={{
              label: 'Start Date',
              name: 'startDate',
              placeholder: 'Type here',
              value: formState.startDate,
              onChange: (date: any) => {
                handleChange({ target: { name: 'startDate', value: date } });
              },
            }}
            renderInput={Input}
          /> */}

          <Input
            label='Start Date'
            type='datetime-local'
            name='startDate'
            value={formState.startDate}
            onChange={handleChange}
          />

          <Input
            label='Activation Date'
            type='datetime-local'
            name='activationDate'
            value={formState.activationDate}
            onChange={handleChange}
          />

          <Input
            label='End Date'
            type='datetime-local'
            name='endDate'
            value={formState.endDate}
            onChange={handleChange}
          />
        </div>

        <Select
          label={'Type of conditions'}
          name={'conditionType'}
          value={formState.joinRequirementsType}
          options={CONDITION_TYPES}
          onChange={(newValue) =>
            handleChange({ target: { name: 'joinRequirementsType', value: newValue } })
          }
          placeholder={'Type here'}
        />

        {formState.joinRequirementsType.value === 'watchingPoints' && (
          <Input
            label='Description of the condition'
            name='conditionDescription'
            placeholder='Type here'
            value={formState.conditionDescription}
            onChange={handleChange}
          />
        )}

        {formState.joinRequirementsType.value === 'watchingPoints' ? (
          <Input
            label='Bet Amount'
            type='number'
            name='betAmount'
            placeholder='Type here'
            onChange={handleChange}
            value={formState.betAmount}
            min={0}
          />
        ) : (
          <>
            <DynamicList
              title='Subscription’s links'
              items={formState.subscriptionLinks}
              onAdd={handleAddLink}
              onDelete={handleDeleteLink}
              onChange={handleChangeLink}
            />
          </>
        )}

        <Input
          label='Reward amount (in points)'
          type='number'
          name='rewardAmount'
          placeholder='Type here'
          onChange={handleChange}
          value={formState.rewardAmount}
          min={0}
        />
      </div>

      <div className={s.modal__footer}>
        {error && <div className={s.modal__error}>{error}</div>}

        <div className={s.modal__row}>
          <Button onClick={onCancel} className={s.modal__btn} isOutlinedWhite>
            Cancel
          </Button>
          <Button className={s.modal__btn} onClick={onSubmit}>
            {isLoadingCreate || isLoadingEdit ? 'Submitting...' : isEdit ? `Edit` : `Add`}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default RaffleModal;
