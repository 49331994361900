import React from 'react';
import s from './style.module.scss';

import AvatarImage from 'src/assets/images/avatar.png';
import { IRafflePlayer } from 'src/services/moderateRafflesService';

const EntryItem = ({ item }: { item: IRafflePlayer }) => {
  return (
    <div className={s.entry}>
      <div>
        <img src={AvatarImage} alt='avatar' />
      </div>

      <div className={s.entry__name}>{item.accountName}</div>
    </div>
  );
};

export default EntryItem;
