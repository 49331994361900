import { useEffect, useState } from 'react';
import { ModalProps } from 'src/layouts/AdminLayout';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { convertDateToIso, convertToDatetimeLocalFormat } from 'src/utils/date';
import moderateRafflesService, {
  IRaffle,
  IRaffleImage,
  RaffleType,
} from 'src/services/moderateRafflesService';
import { toast } from 'react-toastify';
import Modal from 'src/ui/Modal';
import Input from 'src/ui/Input';
import FileUploader from 'src/ui/FileUploader';
import s from './style.module.scss';
import Button from 'src/ui/Button';
import LogoImage from 'src/assets/images/btcs-logo.png';
import Select from 'src/ui/Select';
import DynamicList from '../../DynamicList';
import { createImageUrl } from 'src/utils/url';

export type GiveawayModalProps = {
  editingItem: IRaffle | null;
  onSuccessCreated?: (raffle: IRaffle) => void;
} & ModalProps;

export const CATEGORIES = [
  {
    id: 0,
    value: 'SHUFFLE',
    label: 'Shuffle',
  },
  {
    id: 1,
    value: 'RUNESCAPE',
    label: 'Runescape',
  },
];

const CONDITION_TYPES = [
  {
    label: 'Watching points',
    value: 'watchingPoints',
    id: 'BET',
  },
  {
    label: 'Subscription',
    value: 'subscription',
    id: 'SUBSCRIPTION',
  },
];

interface IFormState {
  name: string;
  type: RaffleType;
  category: (typeof CATEGORIES)[number];
  description: string;
  descriptionItems: string[] | null;
  image: IRaffleImage | null;
  requirements: string;
  // subscriptionLinks: string[];
  // joinRequirementsType: any;
  startDate: string;
  endDate: string;
  activationDate: string;
  link: string;
  // maxPlayers: number;
  // maxWinners: number;
  // rewardAmount: number;
  // watchingTime: number;
  status: string;
  version: number;
}

const clearFormState = {
  name: '',
  type: RaffleType.RAFFLE,
  category: CATEGORIES[0],
  description: '',
  descriptionItems: null,
  startDate: '',
  image: null,
  requirements: '',
  // subscriptionLinks: [''],
  // joinRequirementsType: CONDITION_TYPES[1],
  endDate: '',
  activationDate: '',
  link: '',
  // maxPlayers: 0,
  // maxWinners: 0,
  // watchingTime: 0,
  // rewardAmount: 0,
  status: 'PENDING',
  version: 0,
};

function GiveawayModal({ editingItem, onSuccessCreated, onClose, open }: GiveawayModalProps) {
  const queryClient = useQueryClient();
  const isEdit = !!editingItem;
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<any>('');

  const [formState, setFormState] = useState<IFormState>(clearFormState);

  const { mutate: create, isPending: isLoadingCreate } = useMutation({
    mutationFn: (body: any) => moderateRafflesService.createRaffle(body),
    onSuccess: (data) => {
      if (onSuccessCreated) onSuccessCreated(data);
      queryClient.invalidateQueries({ queryKey: ['giveaways-admin'] });
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error creating giveaway';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Create giveaway error:', error);
    },
  });

  // Edit giveaway
  const { mutate: edit, isPending: isLoadingEdit } = useMutation({
    mutationFn: (body: any) =>
      moderateRafflesService.editRaffle({ id: editingItem?.id || '', body }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['giveaways-admin'] });
      toast.success('Giveaway edited successfully');
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error editing giveaway';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Edit giveaway error:', error);
    },
  });

  // Create image
  const { mutate: createImage, isPending: isLoadingImage } = useMutation({
    mutationFn: (file: FormData) => moderateRafflesService.createImage(file),
    onSuccess: (response) => {
      handleChange({ target: { name: 'image', value: response } });
      setImage(response);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error upload image';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Error upload image:', error);
    },
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const handleChangeImage = async (file: File | null) => {
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file);

    for (const pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
    await createImage(formData);
  };

  const handleDeleteImage = () => {
    setImage('');
    handleChange({ target: { name: 'image', value: null } });
  };

  const formBody = () => {
    const {
      name,
      category,
      description,
      descriptionItems,
      startDate,
      endDate,
      activationDate,
      // maxPlayers,
      image,
      link,
      // maxWinners,
      // subscriptionLinks,
      // joinRequirementsType,
      // watchingTime,
      requirements,
      status,
      // rewardAmount,
      version,
    } = formState;

    const body = {
      name: name,
      type: RaffleType.GIVEAWAY,
      category: category.value,
      description: description,
      startDate: convertDateToIso(startDate),
      endDate: convertDateToIso(endDate),
      activationDate: convertDateToIso(activationDate),
      // joinRequirementsType: joinRequirementsType.id,
      // maxPlayers: 0,
      // maxWinners: 0,
      meta: {
        betAmount: 0,
        requiredSubscriptions: [link],
        description: requirements,
        descriptionItems: descriptionItems === null ? [''] : descriptionItems,
        requiredDuration: [
          {
            platform: '',
            duration: 0,
          },
        ],
      },
      rewardAmount: 0,
      imageId: image?.id,
      status: status,
      version: version,
      players: [],
    };

    return body;
  };

  const onCreate = () => {
    const body = formBody();

    console.log('body', body);
    create(body);
  };

  const onEdit = () => {
    const body = formBody();

    edit(body);
  };

  const onCancel = () => {
    onClose();
    setFormState(clearFormState);
  };

  const onSubmit = () => {
    if (isLoadingCreate || isLoadingEdit) {
      return;
    }

    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
  };

  // const handleAddLink = () => {
  //   handleChange({
  //     target: { name: 'subscriptionLinks', value: [...formState.subscriptionLinks, ''] },
  //   });
  // };

  // const handleDeleteLink = (index: number) => {
  //   const newSubscriptionLinks = formState.subscriptionLinks.filter((_, i) => i !== index);

  //   handleChange({
  //     target: {
  //       name: 'subscriptionLinks',
  //       value: newSubscriptionLinks,
  //     },
  //   });
  // };

  // const handleChangeLink = (index: number, value: string) => {
  //   const newSubscriptionLinks = [...formState.subscriptionLinks];

  //   newSubscriptionLinks[index] = value;

  //   handleChange({
  //     target: {
  //       name: 'subscriptionLinks',
  //       value: newSubscriptionLinks,
  //     },
  //   });
  // };

  const handleAddDescItem = () => {
    handleChange({
      target: { name: 'descriptionItems', value: [...(formState.descriptionItems || []), ''] },
    });
  };

  const handleDeleteDescItem = (index: number) => {
    const newdescriptionItems = (formState.descriptionItems || []).filter((_, i) => i !== index);

    handleChange({
      target: {
        name: 'descriptionItems',
        value: newdescriptionItems,
      },
    });
  };

  const handleChangeDescItem = (index: number, value: string) => {
    const newdescriptionItems = [...(formState.descriptionItems || [])];

    newdescriptionItems[index] = value;

    handleChange({
      target: {
        name: 'descriptionItems',
        value: newdescriptionItems,
      },
    });
  };

  useEffect(() => {
    if (editingItem) {
      const findCategory = CATEGORIES.find((category) => category.value === editingItem.category);
      // const findConditionType = CONDITION_TYPES.find((category) => category.id === editingItem.id);

      setFormState({
        name: editingItem.name || '',
        type: RaffleType.RAFFLE,
        category: findCategory || CATEGORIES[0],
        // joinRequirementsType: findConditionType || CONDITION_TYPES[1],
        description: editingItem.description || '',
        descriptionItems: editingItem.meta.descriptionItems || [''],
        // watchingTime: editingItem.meta.requiredDuration?.[0]?.duration || 0,
        status: editingItem.status || 'PENDING',
        // subscriptionLinks: editingItem.meta.requiredSubscriptions || [''],
        requirements: editingItem.meta.description || '',
        image: editingItem.image || null,
        link: editingItem.meta.description || '',
        startDate: convertToDatetimeLocalFormat(editingItem.startDate) || '',
        endDate: convertToDatetimeLocalFormat(editingItem.endDate) || '',
        activationDate: convertToDatetimeLocalFormat(editingItem.activationDate) || '',
        // maxPlayers: editingItem.maxPlayers || 0,
        // maxWinners: editingItem.maxWinners || 0,
        // rewardAmount: editingItem.rewardAmount || 0,
        version: editingItem.version || 0,
      });
    }
  }, [editingItem]);

  return (
    <Modal open={open} handleClose={handleClose} isCloseIcon>
      <div className={s.modal__header}>
        <img src={LogoImage} alt='logo' />

        <h2>
          {isEdit ? `Edit ` : `Add `}
          Giveaway
        </h2>
      </div>

      <div className={s.modal__form}>
        <Input
          label='Name'
          name='name'
          placeholder='Type here'
          value={formState.name}
          onChange={handleChange}
        />

        <Input
          label='Description'
          name='description'
          placeholder='Type here'
          value={formState.description}
          onChange={handleChange}
        />

        <Input
          label='Link'
          name='link'
          placeholder='https://example.com'
          value={formState.link}
          onChange={handleChange}
        />

        <Input
          label='Requirement description'
          name='requirements'
          placeholder='Type here'
          value={formState.requirements}
          onChange={handleChange}
        />

        <DynamicList
          title='Description list'
          items={formState?.descriptionItems || []}
          onAdd={handleAddDescItem}
          onDelete={handleDeleteDescItem}
          onChange={handleChangeDescItem}
        />

        {isLoadingImage ? (
          <div>Loading image...</div>
        ) : (
          <div style={{ width: 'auto' }}>
            <FileUploader
              value={image}
              setValue={handleChangeImage}
              label='Icon upload'
              url={createImageUrl(image.url)}
              onDelete={handleDeleteImage}
              title="Attach raffle's logo here"
              name='image'
              acceptedFileTypes={['image/jpeg', 'image/png']}
            />
          </div>
        )}

        {/* <div className={s.modal__row}>
          <Input
            label='Limit of participants'
            type='number'
            name='maxPlayers'
            placeholder='Type here'
            onChange={handleChange}
            value={formState.maxPlayers}
            min={0}
          />

          <Input
            label='Number of winners'
            type='number'
            name='maxWinners'
            placeholder='Type here'
            onChange={handleChange}
            value={formState.maxWinners}
            min={0}
          />
        </div> */}

        <Select
          label={'Type of provider'}
          name={'category'}
          //@ts-ignore
          value={formState.category}
          options={CATEGORIES}
          onChange={(newValue) => handleChange({ target: { name: 'category', value: newValue } })}
          placeholder={'Type here'}
        />

        <div className={s.modal__row}>
          <Input
            label='Start Date'
            type='datetime-local'
            name='startDate'
            value={formState.startDate}
            onChange={handleChange}
          />

          <Input
            label='Activation Date'
            type='datetime-local'
            name='activationDate'
            value={formState.activationDate}
            onChange={handleChange}
          />

          <Input
            label='End Date'
            type='datetime-local'
            name='endDate'
            value={formState.endDate}
            onChange={handleChange}
          />
        </div>

        {/* <Input
          label='Duration of watching time (in hours)'
          type='number'
          name='watchingTime'
          placeholder='Type here'
          onChange={handleChange}
          value={formState.watchingTime}
          min={0}
        /> */}

        {/* <DynamicList
          title='Subscription’s links'
          items={formState.subscriptionLinks}
          onAdd={handleAddLink}
          onDelete={handleDeleteLink}
          onChange={handleChangeLink}
        /> */}

        {/* <Input
          label='Reward amount (in points)'
          type='number'
          name='rewardAmount'
          placeholder='Type here'
          onChange={handleChange}
          value={formState.rewardAmount}
          min={0}
        /> */}
      </div>

      <div className={s.modal__footer}>
        {error && <div className={s.modal__error}>{error}</div>}

        <div className={s.modal__row}>
          <Button className={s.modal__btn} onClick={onCancel} isOutlinedWhite>
            Cancel
          </Button>
          <Button className={s.modal__btn} onClick={onSubmit}>
            {isLoadingCreate || isLoadingEdit ? 'Submitting...' : isEdit ? `Edit` : `Add`}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default GiveawayModal;
