import { axiosGet, axiosGetWithHeaders, axiosPost } from 'src/utils/httpClient';
import { IRaffle, RaffleStatus, RaffleType } from './moderateRafflesService';

export interface IGetRafflesResponse {
  data: IRaffle[];
  total: number;
}

const playerService = {
  async getRaffles({
    type,
    status,
    page,
    size,
    category,
  }: {
    type: RaffleType;
    status: RaffleStatus;
    page?: number;
    size?: number;
    category?: string;
  }): Promise<IGetRafflesResponse> {
    let url = `/public/raffles/${type}?status=${status}`;

    if (page !== undefined) url += `&page=${page}`;
    if (size !== undefined) url += `&size=${size}`;
    if (category) url += `&category=${category}`;

    const response = await axiosGetWithHeaders(url);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10) || 20,
    };
  },

  async getRaffle({ id }: { id: string }): Promise<IRaffle> {
    const response = await axiosGet(`/public/raffles/${id}/details`);

    return response;
  },

  async joinToRaffle({ id }: { id: string }): Promise<void> {
    const response = await axiosPost(`/player/raffle/${id}/join`);
    return response;
  },

  async getImage({ id }: { id: string }): Promise<void> {
    const response = await axiosGet(`/public/images/${id}`);
    return response;
  },

  async getPlayersForRaffle({
    id,
    page,
    size,
  }: {
    id: string;
    page: string | number;
    size: string | number;
  }): Promise<{ data: any; total: number }> {
    const response = await axiosGetWithHeaders(
      `/player/raffles/${id}/players?page=${page}&size=${size}`,
    );

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getWinnersForRaffle({ id }: { id: string }): Promise<void> {
    const response = await axiosGet(`/player/raffles/${id}/winners`);
    return response;
  },
};

export default playerService;
