import { Suspense, useEffect, useState } from 'react';

import { Header } from 'src/components/Header';
import { ItemDefinition, PlayerDetails, TwitchAuthCodeGrantFlow } from './types';
import { axiosGet } from './utils/httpClient';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Landing } from './pages/Landing';
import { PlayerProfile } from './pages/PlayerProfile';
import Challenges from './pages/Challenges';
import Highlights from './pages/Highlights';
import Leaderboard from './pages/Leaderboard';
import Casinos from './pages/Casinos';
import { Store } from './pages/Store';
import { SlotBattles } from './pages/SlotBattles';
import Cases from './pages/Cases';
import Hunts from './pages/Hunts';
import Hunt from './pages/Hunt';
import AdminRedirect from './pages/AdminRedirect';
import { VIP } from './pages/VIP';
import Raffles from './pages/Raffles';

import Raffle from './pages/Raffle';
import { PlayerSettings } from './pages/PlayerSettings';
import TermsOfService from './pages/TermsOfService';
import FAQ from './pages/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { ToastContainer, Slide } from 'react-toastify';

import 'src/assets/CSS/null.scss';
import './assets/CSS/App.scss';
import './assets/CSS/App.responsive.scss';
import './assets/CSS/global.scss';
import { routes } from 'src/routes';
import useAuthStore from 'src/store/authStore';
import MenuBurger from 'src/components/MenuBurger';
import NewFooter from 'src/components/Footer';
import Giveaway from './pages/Giveaway';
import Giveaways from './pages/Giveaways';
import AdminLayout from './layouts/AdminLayout';
import RafflesAdmin from './pages/Admin/RafflesAdmin';
import ChallengesAdmin from './pages/Admin/ChallengesAdmin';
import HighlightsAdmin from './pages/Admin/HighlightsAdmin';
import ItemsAdmin from './pages/Admin/ItemsAdmin';
import StoreAdmin from './pages/Admin/StoreAdmin';
import StoreLogs from './pages/Admin/StoreLogs';
import SlotBattlesAdmin from './pages/Admin/SlotBattlesAdmin';
import PlayersAdmin from './pages/Admin/PlayersAdmin';
import PlayerViewAdmin from './pages/Admin/PlayerViewAdmin';
import LeaderboardAdmin from './pages/Admin/LeaderboardAdmin';
import DashbordAdmin from './pages/Admin/DashboardAdmin';
import RaffleAdmin from './pages/Admin/RaffleAdmin';

import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import authService from './services/authService';
import AuthProvider from 'src/providers/AuthProvider';

// TODO: Remove this when the issue is fixed
window.ResizeObserver = class {
  observe() {}
  unobserve() {}
  disconnect() {}
};

const queryClient = new QueryClient();

export default function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <div className='App'>
            <AuthProvider>
              <div className='App-header-container'>
                <Header onToggleMenu={(value: boolean) => setMenuOpen(value)} />
              </div>

              <div className=''>
                {/* <ScrollManager /> */}
                <Routes>
                  <Route element={<Landing />} path={routes.home} />
                  <Route element={<VIP />} path={routes.vip} />
                  <Route path='/admin' element={<AdminLayout />}>
                    <Route path='dashboard' element={<DashbordAdmin />} />
                    <Route path='raffles' element={<RafflesAdmin />} />
                    <Route path='raffles/:id' element={<RaffleAdmin />} />
                    <Route path='challenges' element={<ChallengesAdmin />} />
                    <Route path='highlights' element={<HighlightsAdmin />} />
                    <Route path='items' element={<ItemsAdmin />} />
                    <Route path='store' element={<StoreAdmin />} />
                    <Route path='store-log' element={<StoreLogs />} />
                    <Route path='battles' element={<SlotBattlesAdmin />} />
                    <Route path='users' element={<PlayersAdmin />} />
                    <Route path='users/:id' element={<PlayerViewAdmin />} />
                    <Route path='leaderboard' element={<LeaderboardAdmin />} />
                  </Route>

                  <Route element={<AdminRedirect />} path={routes.admin} />
                  <Route element={<Casinos />} path={routes.casino} />
                  <Route element={<Leaderboard />} path={routes.leaderboard} />
                  <Route element={<Highlights />} path={routes.highlights} />
                  <Route element={<Challenges />} path={routes.challenges} />
                  <Route element={<Giveaways />} path={routes.giveaways} />
                  <Route element={<Giveaway />} path={routes.giveaway} />
                  <Route element={<Raffles />} path={routes.raffles} />
                  <Route element={<Raffle />} path={routes.raffle} />
                  <Route element={<Cases />} path={routes.cases} />
                  <Route element={<SlotBattles />} path={routes.battles} />
                  <Route element={<Hunts />} path={routes.hunts} />
                  <Route element={<Hunt />} path={routes.hunt} />
                  <Route element={<Store />} path={routes.store} />
                  <Route element={<PlayerProfile />} path={routes.profile} />
                  <Route element={<PlayerSettings />} path={routes.settings} />
                  <Route element={<TermsOfService />} path={routes.tos} />
                  <Route element={<FAQ />} path={routes.faq} />
                  <Route element={<PrivacyPolicy />} path={routes.privacyPolicy} />
                </Routes>
              </div>

              <MenuBurger isOpen={isMenuOpen} onClose={() => setMenuOpen(false)} />
              <NewFooter />
            </AuthProvider>
          </div>
        </BrowserRouter>

        <ReactQueryDevtools initialIsOpen={false} position='right' />

        <ToastContainer
          position='bottom-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
          transition={Slide}
        />
      </QueryClientProvider>
    </>
  );
}
