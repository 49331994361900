import React, { useEffect, useState } from 'react';
import s from './style.module.scss';

interface IEndCounterProps {
  title?: string;
  endDate: string;
}

const EndCounter = ({ title, endDate }: IEndCounterProps) => {
  const calculateTimeLeft = () => {
    const difference = new Date(endDate).getTime() - new Date().getTime();

    if (difference > 0) {
      return {
        difference,
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return { difference: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  if (timeLeft.difference <= 0) {
    const formatDate = (dateStr: string) => {
      const dateObj = new Date(dateStr);
      const day = dateObj.getDate().toString().padStart(2, '0');
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
      const year = dateObj.getFullYear();

      return `${day}.${month}.${year}`;
    };

    return (
      <div className={s.block}>
        {title && <div className={s.block__title}>{title}</div>}

        <div className={s.block__endDate}>
          {formatDate(endDate)}
          <span> Completed</span>
        </div>
      </div>
    );
  }

  return (
    <div className={s.block}>
      {title && <div className={s.block__title}>{title}</div>}

      <div className={s.block__counter}>
        <div className={s.block__count}>
          <span>{String(timeLeft.days).padStart(2, '0')}</span>
          <span>DAY</span>
        </div>
        :
        <div className={s.block__count}>
          <span>{String(timeLeft.hours).padStart(2, '0')}</span>
          <span>HR</span>
        </div>
        :
        <div className={s.block__count}>
          <span>{String(timeLeft.minutes).padStart(2, '0')}</span>
          <span>MIN</span>
        </div>
        :
        <div className={s.block__count}>
          <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
          <span>SEC</span>
        </div>
      </div>
    </div>
  );
};

export default EndCounter;
