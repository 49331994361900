import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/leftArrow.svg';
import { ReactComponent as ShuffleLogoIcon } from 'src/assets/icons/shuffleLogo.svg';
import { ReactComponent as RaneScapeLogoIcon } from 'src/assets/icons/RuneScapeLogo.svg';
import s from './style.module.scss';
import Button from 'src/ui/Button';
import { useLocation } from 'react-router';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { links, routes } from 'src/routes';
import PageLink from 'src/components/PageLink';
import GiveawayDetailsCard from 'src/components/giveaways/giveaway/GiveawayDetailsCard';
import moderateRafflesService, {
  IRaffle,
  RaffleCategory,
} from 'src/services/moderateRafflesService';
import usePlayerStore from 'src/store/playerStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import playerService from 'src/services/playerService';
import { toast } from 'react-toastify';
import { REFETCHING_TIME } from 'src/constants/fetching';

const Giveaway = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [item, setItem] = useState<IRaffle | null | undefined>(null);
  const { giveaways, findGiveawayById } = usePlayerStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [backUrl] = useState(location.state?.from || routes.giveaways);
  const playersPage = Number(searchParams.get('playersPage')) || 1;

  const itemsPerPage = 12;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [`raffle-${id}`],
    queryFn: () => playerService.getRaffle({ id: id ? id : '' }),
    enabled: false,
    refetchInterval: REFETCHING_TIME,
  });

  const { mutate } = useMutation({
    mutationKey: ['joinToGiveaway'],
    mutationFn: (id: string) => playerService.joinToRaffle({ id }),
    onSuccess: async () => {
      toast.success('You have successfully joined the giveaway');
      queryClient.removeQueries({ queryKey: ['user-settings'] });
      refetch();
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage.includes('does not have enough coins to join the')) {
        toast.error('Not enough points to participate');
      } else {
        toast.error(errorMessage);
        console.error('Join raffle error:', error);
      }
    },
  });

  const onJoinRaffle = (id: string) => {
    mutate(id);
  };

  useEffect(() => {
    if (data) {
      setItem(data);
      queryClient.invalidateQueries({ queryKey: ['publicCurrentGiveaways'] });
    }
  }, [data]);

  useEffect(() => {
    // setSearchParams({ playersPage: '1' });

    if (id && giveaways.length > 0) {
      setItem(findGiveawayById(id));
    } else {
      refetch();
    }
  }, []);

  // const handleChangePage = (value: number) => {
  //   setSearchParams({ playersPage: value.toString() });
  // };

  if (isLoading && !item) {
    return <div>Loading...</div>;
  }

  if (!isError && !item) {
    return <div>Loading...</div>;
  }

  if (isError && !item) {
    return <div>Not found</div>;
  }

  if (!item) {
    return <div>Not found</div>;
  }

  return (
    <div className='wrapper'>
      <div className='container'>
        <div className={s.raffle}>
          <div className={s.raffle__head}>
            <PageLink to={backUrl}>
              <LeftArrowIcon />
              Back to All Giveaways
            </PageLink>

            <h3 className={s.raffle__title}>Giveaway</h3>

            {item.meta.providerType === RaffleCategory.SHUFFLE ? (
              <Link to={links.shuffle}>
                <Button>
                  <ShuffleLogoIcon />
                </Button>
              </Link>
            ) : (
              <Link to={links.runescape}>
                <Button>
                  <RaneScapeLogoIcon />
                </Button>
              </Link>
            )}
          </div>

          <div className={s.raffle__card}>
            <GiveawayDetailsCard item={item} onJoinClick={() => onJoinRaffle(item.id)} />
          </div>

          {/* <RaffleParticipantsSection
            isLoading={isLoadingPlayers}
            playersData={playersData}
            itemsPerPage={itemsPerPage}
            playersPage={playersPage}
            handleChangePage={handleChangePage}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Giveaway;
