import React, { useEffect, useState } from 'react';
import { Player } from 'src/types';
import { axiosGet } from 'src/utils/httpClient';
import { useStyles } from 'src/layouts/AdminLayout';
import { useNavigate } from 'react-router-dom';
import 'src/assets/CSS/Admin.scss';
import { formatDateYearTime } from 'src/components/Crates';

const PlayersAdmin = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const getPlayers = async (): Promise<Array<Player>> => axiosGet(`/admin/players`);
  const [players, setPlayers] = useState<Array<Player>>([]);
  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    getPlayers().then((response: Array<Player>) => {
      setPlayers(response);
    });
  }, []);

  return (
    <div>
      <div className='Section-heading'>
        <h4>Players</h4>
      </div>

      <div className='Bonus-hunt-search'>
        <div className='search-input'>
          <img src='/navigation/search.svg' width={20} />
          <input
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <div className='Btcs-table'>
        <ul className='Header'>
          <li>Name</li>
          <li>Rank</li>
          <li>Last Online</li>
        </ul>
        {players
          .filter((player) => {
            return !search || player.username.toLowerCase().includes(search.toLowerCase());
          })
          .map((player) => {
            return (
              <ul
                className='Entry'
                onClick={() => {
                  // navigate(`/admin/users/${player.id}`);
                  window.location.href = `https://btcs.gg/admin/users/${player.id}`;
                }}>
                <li>{player.username}</li>
                <li>{player.authority}</li>
                <li>{formatDateYearTime(player.lastOnline)}</li>
              </ul>
            );
          })}
      </div>
    </div>
  );
};

export default PlayersAdmin;
