import {
  axiosDelete,
  axiosGet,
  axiosGetWithHeaders,
  axiosPost,
  axiosPut,
} from 'src/utils/httpClient';
import { IGetRafflesResponse } from './playerService';

export enum RaffleType {
  GIVEAWAY = 'GIVEAWAY',
  RAFFLE = 'RAFFLE',
}

export enum RaffleCategory {
  NONE = 'NONE',
  SHUFFLE = 'SHUFFLE',
  RUNESCAPE = 'RUNESCAPE',
}

export enum RaffleStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
}

export interface IRaffleImage {
  id: string;
  mediaType: string;
  addedOn: string;
  url: string;
}

export enum RewardStatus {
  PENDING = 'PENDING',
  REWARDED = 'REWARDED',
}

export enum RaffleJoinRequirementsType {
  BET = 'BET',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface IRafflePlayer {
  id: string;
  accountName: string;
  signupDate: string;
  lastSeen: string;
  points: number;
  rewardStatus: RewardStatus;
  banned: boolean;
}

export interface IRaffle {
  id: string;
  name: string;
  type: RaffleType;
  category: RaffleCategory;
  imageId: null | string;
  description: string;
  startDate: string;
  endDate: string;
  activationDate: string;
  joinRequirementsType: any;
  maxPlayers: number;
  maxWinners: number;
  meta: {
    betAmount: 0.0;
    requiredSubscriptions: ['string'];
    providerType: RaffleCategory;
    requiredDuration: [
      {
        platform: 'string';
        duration: 0;
      },
    ];
    description: string;
    descriptionItems: string[];
  };
  rewardAmount: 0.0;
  status: RaffleStatus;
  version: number;
  image: IRaffleImage | null;
  players: IRafflePlayer[];
  winners: IRafflePlayer[];
  joined: boolean;
  numberOfPlayers: number;
  numberOfWinners: number;
}

export interface IBanUserBody {
  reason: string;
  banType: string;
  entityId?: string;
}

export enum BanUserType {
  ENTIRE_ACCOUNT = 'ENTIRE_ACCOUNT',
  RAFFLES = 'RAFFLES',
  GIVEAWAYS = 'GIVEAWAYS',
  GAME_ID = 'GAME_ID',
}

const moderateRafflesService = {
  async createRaffle(body: any) {
    const response = await axiosPost('/moderate/raffles', body);

    return response;
  },

  async editRaffle({ id, body }: { id: string; body: IRaffle }) {
    const response = await axiosPut(`/moderate/raffles/${id}`, body);

    return response;
  },

  async editRaffleStatus({ id, status }: { id: string; status: RaffleStatus }) {
    const response = await axiosPut(`/moderate/raffles/${id}/${status}`);

    return response;
  },

  async getRaffle({ id }: { id: string }): Promise<IRaffle> {
    const response = await axiosGet(`/moderate/raffles/${id}`);

    return response;
  },

  async getRaffles({
    type,
    status,
    page,
    size,
    sortColumn,
    sortOrder,
  }: {
    type: RaffleType;
    status?: RaffleStatus;
    page?: number;
    size?: number;
    sortColumn?: string | null;
    sortOrder?: string;
  }): Promise<IGetRafflesResponse> {
    let url = `/moderate/raffles?type=${type}&page=${page}&size=${size}`;

    if (sortColumn && sortOrder) {
      url += `&sort=${sortColumn},${sortOrder}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    const response = await axiosGetWithHeaders(url);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10) || 20,
    };
  },

  async getGiveawaysRaffles({
    type,
    status,
  }: {
    type: RaffleType;
    status: RaffleStatus;
  }): Promise<IRaffle[]> {
    const response = await axiosGet(`/player/${type}/${status}`);

    return response;
  },

  async deleteRaffle(id: string) {
    const response = await axiosDelete(`/moderate/raffles/${id}`);

    return response;
  },

  async createImage(file: FormData) {
    const response = await axiosPost('/moderate/images', file);

    return response;
  },

  async getReward({ raffleId, accountId }: { raffleId: string; accountId: string }) {
    const response = await axiosPost(`/moderate/raffle/${raffleId}/reward/${accountId}`);

    return response;
  },

  async banUser({ accountId, body }: { accountId: string; body: IBanUserBody }) {
    const response = await axiosPost(`/moderate/player/${accountId}/ban`, body);

    return response;
  },

  async unbanUser({ accountId, body }: { accountId: string; body: IBanUserBody }) {
    const response = await axiosPost(`/moderate/player/${accountId}/unban`, body);

    return response;
  },

  async getPlayersForRaffle({
    id,
    page,
    size,
  }: {
    id: string;
    page: string | number;
    size: string | number;
  }): Promise<{ data: any; total: number }> {
    const response = await axiosGetWithHeaders(
      `/moderate/raffles/${id}/players?page=${page}&size=${size}`,
    );

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getWinnersForRaffle({
    id,
    page,
    size,
  }: {
    id: string;
    page?: string | number;
    size?: string | number;
  }): Promise<{ data: any; total: number }> {
    const response = await axiosGetWithHeaders(
      // `/moderate/raffles/${id}/winners`,
      `/moderate/raffles/${id}/winners?page=${page}&size=${size}`,
    );

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async winnersSelection({ id }: { id: string }) {
    const response = await axiosPost(`/moderate/raffles/${id}/run/winners-selection`);

    return response;
  },
};

export default moderateRafflesService;
