import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { formatDateYearTime } from 'src/components/Crates';

const DashboardAdmin = () => {
  const { stats, players, playersShuffle } = useOutletContext<any>();

  return (
    <div className='App-contents Dashboard'>
      <div className='Dashboard-Metrics'>
        <div className='MetricCard'>
          <h6>{stats ? stats.signupsToday : '-'}</h6>
          <h3>Signups Today</h3>
        </div>
        <div className='MetricCard'>
          <h6>{stats ? stats.signupsWeek : '-'}</h6>
          <h3>Signups This Week</h3>
        </div>
        <div className='MetricCard'>
          <h6>{stats ? stats.signupsMonth : '-'}</h6>
          <h3>Signups This Month</h3>
        </div>
        <div className='MetricCard'>
          <h6>{stats ? stats.rewardsClaimed : '-'}</h6>
          <h3>Rewards Claimed</h3>
        </div>
        <div className='MetricCard'>
          <h6>{stats ? stats.casesOpened : '-'}</h6>
          <h3>Cases Opened</h3>
        </div>

        <a href='https://stats.jcy.gg/share/dyeEJdk57xpziI3c/BTCS.GG' target='_action'>
          <button>Stats</button>
        </a>
      </div>
      <div className='Dashboard-Pending'>
        <span>Pending Transactions</span>

        <div className='Btcs-table' style={{ width: '100%' }}>
          <ul className='Header'>
            <li>Name</li>
            <li>Rank</li>
            <li>Last Online</li>
          </ul>
          {players.map((player) => {
            return (
              <ul
                className='Entry'
                onClick={() => {
                  window.location.href = `https://btcs.gg/admin/users/${player.id}`;
                }}>
                <li>{player.username}</li>
                <li>{player.authority}</li>
                <li>{formatDateYearTime(player.lastOnline)}</li>
              </ul>
            );
          })}
        </div>
      </div>
      <div className='Dashboard-Pending'>
        <span>Pending Shuffle Username Verification</span>

        <div className='Btcs-table' style={{ width: '100%' }}>
          <ul className='Header'>
            <li>Name</li>
            <li>Rank</li>
            <li>Last Online</li>
          </ul>
          {playersShuffle.map((player) => {
            return (
              <ul
                className='Entry'
                onClick={() => {
                  window.location.href = `https://btcs.gg/admin/users/${player.id}`;
                }}>
                <li>{player.username}</li>
                <li>{player.authority}</li>
                <li>{formatDateYearTime(player.lastOnline)}</li>
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
