import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/leftArrow.svg';
import s from './style.module.scss';
import RaffleDetailsCard from 'src/components/raffles/RaffleDetailsCard';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { routes } from 'src/routes';
import PageLink from 'src/components/PageLink';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moderateRafflesService, { IRaffle } from 'src/services/moderateRafflesService';
import { toast } from 'react-toastify';
import playerService from 'src/services/playerService';
import usePlayerStore from 'src/store/playerStore';
import RaffleParticipantsSection from 'src/components/raffles/RaffleParticipantsSection';
import userService from 'src/services/userService';
import useAuthStore from 'src/store/authStore';
import { REFETCHING_TIME } from 'src/constants/fetching';

const Raffle = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const { raffles, findRaffleById, findPreviousRaffleById } = usePlayerStore();
  const [item, setItem] = useState<IRaffle | null | undefined>(null);
  const itemsPerPage = 12;
  const playersPage = Number(searchParams.get('playersPage')) || 1;
  const { updateField } = useAuthStore();
  const location = useLocation();
  const [backUrl] = useState(location.state?.from || routes.raffles);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['raffle', id],
    queryFn: () => playerService.getRaffle({ id: id ? id : '' }),
    enabled: false,
    refetchInterval: REFETCHING_TIME,
  });

  const {
    data: playersData,
    isLoading: isLoadingPlayers,
    refetch: refetchPlayers,
  } = useQuery({
    queryKey: ['players', id],
    queryFn: () =>
      playerService.getPlayersForRaffle({
        id: id ? id : '',
        page: playersPage === 0 ? playersPage : playersPage - 1,
        size: itemsPerPage,
      }),
    refetchInterval: REFETCHING_TIME,
  });

  const { mutate } = useMutation({
    mutationKey: ['joinToRaffle'],
    mutationFn: (id: string) => playerService.joinToRaffle({ id }),
    onSuccess: async () => {
      toast.success('You have successfully joined the raffle');
      queryClient.invalidateQueries({
        queryKey: ['user-settings'],
        refetchInactive: true,
      });
      refetch();
      refetchPlayers();
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage.includes('does not have enough coins to join the')) {
        toast.error('Not enough points to participate');
      } else {
        toast.error(errorMessage);
        console.error('Join raffle error:', error);
      }
    },
  });

  const onJoinRaffle = (id: string) => {
    mutate(id);
  };

  const handleChangePage = (value: number) => {
    setSearchParams({ playersPage: value.toString() });
  };

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  useEffect(() => {
    setSearchParams({ playersPage: '1' });

    if (id && raffles.length > 0) {
      const raffle = findRaffleById(id);

      if (raffle) {
        setItem(raffle);
      } else {
        setItem(findPreviousRaffleById(id));
      }
    } else {
      refetch();
    }
  }, []);

  if (isLoading && !item) {
    return <div>Loading...</div>;
  }

  if (!isError && !item) {
    return <div>Loading...</div>;
  }

  if (isError && !item) {
    return <div>Not found</div>;
  }

  if (!item) {
    return <div>Not found</div>;
  }

  return (
    <div className='wrapper'>
      <div className='container'>
        <div className={s.raffle}>
          <div>
            <PageLink to={backUrl}>
              <LeftArrowIcon />
              Back to All Raffles
            </PageLink>

            <h3 className={s.raffle__title}>{item.name}</h3>
          </div>

          <div className={s.raffle__card}>
            <RaffleDetailsCard
              isUserJoined={item.joined}
              onJoinClick={() => onJoinRaffle(id!)}
              item={item}
            />
          </div>

          <RaffleParticipantsSection
            isLoading={isLoadingPlayers}
            playersData={playersData}
            itemsPerPage={itemsPerPage}
            playersPage={playersPage}
            handleChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default Raffle;
