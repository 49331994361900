import React, { useEffect } from 'react';
import { PlayerDetails } from '../../types';
import StarIcon from '@mui/icons-material/Star';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import useAuthStore from 'src/store/authStore';

import { ReactComponent as CasinoIcon } from 'src/assets/icons/casino.svg';
import { ReactComponent as GiftIcon } from 'src/assets/icons/giftSecond.svg';
import { ReactComponent as GraphIcon } from 'src/assets/icons/graph.svg';
import { ReactComponent as CrownIcon } from 'src/assets/icons/crown.svg';
import { ReactComponent as RafflesIcon } from 'src/assets/icons/raffles.svg';
import { ReactComponent as BurgerMenuIcon } from 'src/assets/icons/burgerMenu.svg';
import { useNavigate, Link } from 'react-router-dom';
import { routes } from 'src/routes';
import authService from 'src/services/authService';

import s from './style.module.scss';
import Divider from 'src/ui/Divider';
import Button from 'src/ui/Button';
import { useQuery } from '@tanstack/react-query';
import userService from 'src/services/userService';
import SettingsModal from '../SettingsModal';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '25ch',
      background: 'transparent !important',
      borderRadius: '10px',
    },
  },
  menuItem: {
    background: 'red !important',
  },
  menu: {},
}));

const navigationItems = [
  { title: 'Casino', width: 25, url: routes.casino, icon: <CasinoIcon /> },
  { title: 'Raffles', width: 25, url: routes.raffles, icon: <RafflesIcon /> },
  { title: 'Challenges', width: 25, url: routes.challenges, icon: <GraphIcon /> },
  { title: 'Giveaways', width: 25, url: routes.giveaways, icon: <GiftIcon /> },
];

export const Login = (redirectUrl?: string) => {
  const client_id = process.env.REACT_APP_TWITCH_CLIENT_ID;
  const twichRedirect = process.env.REACT_APP_TWITCH_REDIRECT_URI;
  const redirect_uri = redirectUrl ? `${twichRedirect}${redirectUrl}` : twichRedirect;

  const response_type = 'code';
  const scopes = ['chat', 'edit+chat', 'read+openid'];
  const scope = scopes.reduce((s1, s2) => s1 + ':' + s2);
  const params = `client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}`;
  const url = `https://id.twitch.tv/oauth2/authorize?${params}`;

  return { url: url };
};

export type HeaderProps = {
  onToggleMenu: (value: boolean) => void;
};

export const Header: React.FC<HeaderProps> = ({ onToggleMenu }) => {
  const navigate = useNavigate();
  const { updateField } = useAuthStore();
  const { user, userSettings, setUser } = useAuthStore();
  const hasToken = localStorage.getItem('bearer') !== null;

  const classes = useStyles();

  // FIX:
  const { data: settings } = useQuery({
    queryKey: ['user-settings'],
    queryFn: userService.getUserSettings,
    enabled: hasToken,
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    await authService.handleLogout();
    navigate(routes.home);
    setUser(null);
  };

  const { url } = Login();

  useEffect(() => {
    if (settings) {
      updateField('userSettings', settings);
    }
  }, [settings]);

  function getProfile(
    user: PlayerDetails | null,
    handleClick: any,
    handleClose: any,
    openMenu: any,
    anchorEl: any,
    classes: any,
  ) {
    if (!user) {
      return (
        <div className={s.login}>
          <Link to={url}>
            <Button borderRadius='6px' className={s.login__btn}>
              Login
            </Button>
          </Link>
        </div>
      );
    }

    const onClickOption = () => {
      handleClose();
    };

    return (
      <>
        <div className={s.profile}>
          <div className={s.profile__container}>
            <div className={s.profile__img} onClick={handleClick}>
              <img src={user.profilePicture} alt='avatar' />
            </div>
            <div className={s.profile__details} onClick={handleClick}>
              <p className={s.profile__username}>{user.username}</p>
              <div className={s.profile__points}>
                <img src='/newcoin.svg' />
                <span>{userSettings?.currentPoints}</span>
              </div>
            </div>

            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              className={'Desktop-nav'}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem
                classes={classes.menuItem}
                onClick={() => {
                  navigate(routes.profile);
                  onClickOption();
                }}>
                Profile
              </MenuItem>
              <MenuItem
                classes={classes.menuItem}
                onClick={() => {
                  setOpen(true);
                  onClickOption();
                }}>
                Settings
              </MenuItem>
              {user!.authority === 'USER' ? (
                <></>
              ) : (
                <MenuItem
                  onClick={() => {
                    navigate(routes.adminDashboard);
                    onClickOption();
                  }}>
                  Admin
                </MenuItem>
              )}
              <MenuItem
                classes={classes.menuItem}
                onClick={() => {
                  onClickOption();
                  onLogout();
                }}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>

        {/* <SettingsModal open={open} onClose={() => setOpen(false)} details={user} /> */}
      </>
    );
  }

  return (
    <header className={s.header}>
      <div className={s.header__left}>
        <Link className={s.header__logo} to={routes.home}>
          <img width={45} height={45} src='/btcs-logo.png' alt='logo' />
        </Link>

        <Link className={s.header__star} to={routes.vip}>
          <StarIcon className='VIP' />
        </Link>
      </div>

      <div className={s.header__center}>
        <ul className={s.header__navList}>
          {navigationItems.map((item) => {
            return (
              <li className={s.header__link} key={item.title}>
                <Link to={item.url} className={s.header__navItem}>
                  {item.icon}
                  <p>{item.title}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={s.header__right}>
        <Link to={routes.leaderboard} className={s.leaderboard}>
          <CrownIcon />
          <div className={s.leaderboard__content}>
            <div style={{ fontSize: '18px' }}>Leaderboard</div>
            <div className={s.leaderboard__content_bold} style={{ fontSize: '16px' }}>
              $50,000
            </div>
          </div>
        </Link>

        <Divider style={{ height: '42px' }} orientation='vertical' />
        {getProfile(user, handleClick, handleClose, openMenu, anchorEl, classes)}
      </div>

      <div className={s.header__menu}>
        <Button isClear>
          <BurgerMenuIcon onClick={() => onToggleMenu(true)} />
        </Button>
      </div>
    </header>
  );
};
