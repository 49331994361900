import { ReactComponent as GiftIcon } from 'src/assets/icons/gift.svg';
import { ReactComponent as TwichTimerIcon } from 'src/assets/icons/twichTimer.svg';
import { ReactComponent as KickTimerIcon } from 'src/assets/icons/kickTimer.svg';
import RaffleEndCounter from 'src/components/EndCounter';
import RaffleParticipants from 'src/components/raffles/RaffleParticipants';
import Button from 'src/ui/Button';

import BTCsShuffleCoinsImage from 'src/assets/images/BTCsShuffleCoins.png';

import s from './style.module.scss';
import StatusBadge, { Status } from 'src/components/StatusBadge';
import useAuthStore from 'src/store/authStore';
import useModal from 'src/hooks/useModal';
import Modal from 'src/ui/Modal';
import LoginWarning from 'src/components/LoginWarning';
import Divider from 'src/ui/Divider';
import { IRaffle, RaffleStatus } from 'src/services/moderateRafflesService';
import { Link } from 'react-router-dom';
import PlatformIcon from 'src/components/PlatformIcon';
import { createImageUrl, createPublicImageUrl } from 'src/utils/url';

interface IGiveawayDetailsCardProps {
  item: IRaffle;
  isAdmin?: boolean;
  onJoinClick?: () => void;
}

const GiveawayDetailsCard = ({ item, isAdmin, onJoinClick }: IGiveawayDetailsCardProps) => {
  const { user, userSettings } = useAuthStore();
  const { isOpen, openModal, closeModal } = useModal();
  const isUserLoggedIn = user !== null;

  // const handleJoinClick = () => {
  //   if (!isUserLoggedIn) {
  //     // show login modal
  //     openModal();
  //   } else {
  //     if (onJoinClick) onJoinClick();
  //   }
  // };

  const getStatusForBadge = (status: RaffleStatus): Status => {
    return status.toLowerCase() as Status;
  };

  const formImageUrl = () => {
    if (isAdmin && item.image) {
      return createImageUrl(item.image?.url);
    } else if (!isAdmin && item.imageId) {
      return createPublicImageUrl(item?.imageId);
    } else {
      return BTCsShuffleCoinsImage;
    }
  };

  return (
    <>
      <div className={s.card}>
        <div className={s.card__block1}>
          <div className={s.card__img}>
            <img src={formImageUrl()} alt='coins' />
          </div>

          <div className={s.card__info}>
            {/* <StatusBadge status={Status.Active} title={'Active'} /> */}
            <StatusBadge status={getStatusForBadge(item.status)} title={item.status} />

            <div className={s.card__title}>{item.name}</div>

            <div className={s.card__text}>
              <div className={s.card__desc}>{item.description}</div>
              <ul className={s.card__descList}>
                {item.meta.descriptionItems.map((descItem, index) => (
                  <li key={index}>{descItem}</li>
                ))}
              </ul>
            </div>
          </div>

          {/* <div className={s.card__prize}>
            <GiftIcon />
            {item.rewardAmount} Points <span>per winner</span>
          </div> */}

          <div className={s.card__timer}>
            <RaffleEndCounter title='End Date' endDate={item.endDate} />
          </div>
        </div>

        {/* <div className={s.card__divider} /> */}

        {/* <div className={s.card__block2}>
          <RaffleParticipants
            title='Participants'
            winners={item.numberOfWinners}
            participants={item.numberOfPlayers}
            maxParticipants={item.maxPlayers}
          />
          <div className={s.card__timers}>
            <div className={s.card__timer}>
              <KickTimerIcon />
              {item.meta.requiredDuration[0].duration} HR
            </div>

            <Divider orientation={'vertical'} />

            <div className={s.card__timer}>
              <TwichTimerIcon />
              {item.meta.requiredDuration[0].duration} HR
            </div>
          </div>
        </div> */}

        <div className={s.card__divider} />

        <div className={s.card__block3}>
          <div className={s.card__requirements}>
            <div className={s.item}>
              <div className={s.item__title}>Requirements</div>

              <div className={s.item__desc}>
                {item.meta.description
                  ? item.meta.description
                  : 'To participate in the draw, subscribe to social networks'}
                {item.meta.description}
              </div>
            </div>

            {/* <div className={s.item}>
              <div className={s.item__title}>Subscribe</div>

              <div className={s.item__socials}>
                {item.meta.requiredSubscriptions.map((link, index) => (
                  <Link to={link} key={index} target='_blank'>
                    <Button className={s.item__btn} isIcon>
                      <PlatformIcon url={link} />
                    </Button>
                  </Link>
                ))}
              </div>
            </div> */}
          </div>

          {!isAdmin && (
            <Link
              // style={{ display: 'block', width: '100%' }}
              target='_blank'
              to={item.meta.requiredSubscriptions[0]}>
              <Button customStyle={{ width: '100%', height: '71.5px' }}>Join</Button>
            </Link>
          )}
        </div>
      </div>

      <Modal maxWidth='sm' isCloseIcon open={isOpen} handleClose={closeModal}>
        <LoginWarning />
      </Modal>
    </>
  );
};

export default GiveawayDetailsCard;
