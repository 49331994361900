import s from './style.module.scss';
import moderateRafflesService, {
  IRafflePlayer,
  RewardStatus,
} from 'src/services/moderateRafflesService';
import Pagination from 'src/ui/Pagination';
import EntryItem from '../EntryItem';
import { Tab, TabList, TabPanel, Tabs } from 'src/ui/Tabs';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { REFETCHING_TIME } from 'src/constants/fetching';

const RaffleParticipantsSection = ({ itemsPerPage, playersPage, handleChangePage, onBan }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [bannedPlayers, setBannedPlayers] = useState<IRafflePlayer[]>([]);
  const [winnersPlayers, setWinnersPlayers] = useState<IRafflePlayer[]>([]);

  const tab = Number(searchParams.get('tab'));

  const {
    data: playersData,
    isLoading: isLoadingPlayers,
    refetch: refetchPlayers,
  } = useQuery({
    queryKey: ['players', id],
    queryFn: () =>
      moderateRafflesService.getPlayersForRaffle({
        id: id ? id : '',
        page: playersPage === 0 ? playersPage : playersPage - 1,
        size: itemsPerPage,
      }),
    refetchInterval: REFETCHING_TIME,
  });

  const {
    data: winnersData,
    isLoading: isLoadingWinners,
    refetch: refetchWinners,
  } = useQuery({
    queryKey: ['winners', id],
    queryFn: () =>
      moderateRafflesService.getWinnersForRaffle({
        id: id ? id : '',
        page: playersPage === 0 ? playersPage : playersPage - 1,
        size: itemsPerPage,
      }),
    refetchInterval: REFETCHING_TIME,
  });

  const { mutate: getReward } = useMutation({
    mutationKey: ['getReward', id],
    mutationFn: (variables: any) => moderateRafflesService.getReward(variables),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['winners', id] });
      toast.success('Winner rewarded');
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error reward winner';
      toast.error(errorMessage);
      console.error('Winner reward error:', error);
    },
  });

  const onGiveReward = (accountId: string) => {
    //FIX: update user accout when reward (if it is your own raffle)
    getReward({ raffleId: id, accountId });
  };

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('tab', '0');
      return prev;
    });
  }, []);

  useEffect(() => {
    if (playersData) {
      const banned = playersData.data.filter((item: IRafflePlayer) => item.banned);
      setBannedPlayers(banned);
    }
  }, [playersData]);

  useEffect(() => {
    if (winnersData) {
      const winners = winnersData.data.filter((item: IRafflePlayer) => item.banned !== true);
      setWinnersPlayers(winners);
    }
  }, [winnersData]);

  const handleChangeTab = (tab: number) => {
    // if (tab === 0) {
    //   refetchPlayers();
    // } else if (tab === 1) {
    //   refetchWinners();
    // } else if (tab === 2) {
    //   refetchPlayers();
    // }

    setSearchParams((prev) => {
      prev.set('tab', tab.toString());
      return prev;
    });
  };

  if (isLoadingPlayers || isLoadingWinners) {
    return <div>Loading...</div>;
  }

  return (
    <div className={s.entries}>
      <div className={s.entries__head}>
        <h4 className={s.entries__title}>
          Participants <span>({playersData?.total})</span>
        </h4>

        {/* <div>
          {playersData!.total > 0 && (
            <Input
              placeholder='Search'
              className={s.raffle__search}
              value={''}
              onChange={(e) => console.log('search', e.target.value)}
            />
          )}
        </div> */}
      </div>

      <Tabs selectedIndex={tab} onSelect={(index) => handleChangeTab(index)}>
        <div style={{ marginBottom: '50px' }}>
          <TabList className={s.tabs__tabList}>
            <Tab>Participants</Tab>
            <Tab>Winners</Tab>
            <Tab>Banned</Tab>
          </TabList>
        </div>
        <TabPanel>
          {playersData?.total === 0 ? (
            <div className={s.raffle__empty}>
              <h4>No Participants Yet</h4>
            </div>
          ) : (
            <div className={s.entries__content}>
              <ul className={s.entries__list}>
                {playersData?.data.map((item, index) => {
                  return (
                    <li key={index}>
                      <EntryItem onBan={() => onBan(item)} item={item} />
                    </li>
                  );
                })}
              </ul>

              {playersData && playersData.total > itemsPerPage && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  count={Math.ceil(playersData?.total / itemsPerPage)}
                  page={playersPage}
                  onChangePage={(e, value) => handleChangePage(value)}
                />
              )}
            </div>
          )}
        </TabPanel>

        <TabPanel>
          {winnersData?.total === 0 ? (
            <div className={s.raffle__empty}>
              <h4>No Winners Yet</h4>
            </div>
          ) : (
            <div className={s.entries__content}>
              <ul className={s.entries__list}>
                {winnersPlayers.map((item, index) => {
                  const isRewarded = item?.rewardStatus === RewardStatus.REWARDED;

                  return (
                    <li key={index}>
                      <EntryItem
                        isWinner={true}
                        isRewarded={isRewarded}
                        onReward={!isRewarded ? onGiveReward : undefined}
                        onBan={() => onBan(item)}
                        item={item}
                      />
                    </li>
                  );
                })}
              </ul>

              {winnersData && winnersData.total > itemsPerPage && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  count={Math.ceil(winnersData?.total / itemsPerPage)}
                  page={playersPage}
                  onChangePage={(e, value) => handleChangePage(value)}
                />
              )}
            </div>
          )}
        </TabPanel>

        <TabPanel>
          {bannedPlayers.length === 0 ? (
            <div className={s.raffle__empty}>
              <h4>No Banned Yet</h4>
            </div>
          ) : (
            <div className={s.entries__content}>
              <ul className={s.entries__list}>
                {bannedPlayers.map((item, index) => {
                  return (
                    <li key={index}>
                      <EntryItem onBan={() => onBan(item)} item={item} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default RaffleParticipantsSection;
